import { useCallback } from "react";
import axios from 'axios';
import config from "../config";
import { ShareServiceClient } from "@azure/storage-file-share";


const shareName = config.shareFileName;
const directoryName = config.tempDirectory

const useFileService = () => {

  const generateSas = async () => {
    const { data } = await axios.get(config.generateSas);
    return data;
  }

  const expired = (sas: string) => {
    if (!sas)
      return true;

    const params = new URLSearchParams(sas)
    const sasExpiration = new Date(params.get('se') || '')
    return (
      !sasExpiration ||
      Math.round(
        (new Date(sasExpiration).getTime() - new Date().getTime()) / 60000
      ) < 10
    )
  }

  const getSas = useCallback(async () => {
    let sas = localStorage.getItem(`${shareName}-sas`) || '';
    if (expired(sas)) {
      sas = await generateSas();
      localStorage.setItem(`${shareName}-sas`, sas);
    }
    return sas;
  }, []);

  const getClient = async () => {
    const sas = await getSas()
    if (sas) {
      const serviceClientWithSAS = new ShareServiceClient(
        `${config.fileServiceUrl}?${sas}`
      );
      return serviceClientWithSAS
    }
  };

  const upload = async (file: Blob, filePath: string, employeeId: string, directory?: string) => {

    const serviceClient = await getClient();
    if (serviceClient) {
      const shareClient = serviceClient.getShareClient(shareName)
      if (!directory)
        directory = getTempDirectoryUrl(employeeId)
      const directoryClient = shareClient.getDirectoryClient(directory);
      if (!directoryClient.exists())
        await directoryClient.create()
      const fileClient = directoryClient.getFileClient(filePath);
      await fileClient.uploadData(file)
      return fileClient
    }
  };

  const remove = async (fileName: string, employeeId: string) => {
    const serviceClient = await getClient();
    if (serviceClient) {
      const directory = getTempDirectoryUrl(employeeId)
      const fileClient = serviceClient.getShareClient(shareName).getDirectoryClient(directory).getFileClient(fileName)
      if (fileClient && await fileClient.exists()) {
        fileClient.delete()
      }
    }
  };

  const getTempDirectoryUrl = (employeeId?: string) => {
    return `${directoryName}/${employeeId}`
  }
  return { getClient, upload, remove };
}

export default useFileService;