import { format } from "date-fns";
import { PDFDocument, PDFCheckBox, PDFTextField, PDFButton, TextAlignment } from "pdf-lib";
import capitalize from "./capitalize";
import fontkit from "@pdf-lib/fontkit";
import i18n from '../i18n'
import { isMarried } from "../validations/validators";


async function generatePdf(data) {
    // Fetch the PDF with form fields
    const formUrl = '/101FormTemplate2023.pdf'
    const formPdfBytes = await fetch(formUrl).then(res => res.arrayBuffer())

    // Load a PDF with form fields
    const pdfDoc = await PDFDocument.load(formPdfBytes)

    // Get the form containing all the fields
    const form = pdfDoc.getForm()

    const fontUrl = '/OpenSans-Regular.ttf'
    const uint8ArrayFont = await fetch(fontUrl).then(res => res.arrayBuffer())
    await pdfDoc.registerFontkit(fontkit);
    const embedfont = await pdfDoc.embedFont(uint8ArrayFont);

    try {
        extractSpecialFields(data);
        Object.entries(data).forEach(async ([key, value]) => {
            key = capitalize(key);
            let field = form.getFieldMaybe(key)
            if (!field) return;

            if (field instanceof PDFCheckBox) {
                if (value)
                    field.check();
                else
                    field.uncheck();
            }

            else if (field instanceof PDFButton) {
                const img = await pdfDoc.embedPng(value);
                field.setImage(img);
            }
            else if (field instanceof PDFTextField) {
                if (value && key.indexOf('Date') > -1) {
                    value = new Date(value);
                }
                if (value instanceof Date)
                    value = format(value, "dd/MM/yyyy");

                field.setText(value ? `${value}` : '');

                field.setFontSize(9);
                field.setAlignment(TextAlignment.Right);
            }
        });

        form.updateFieldAppearances(embedfont);

        // Serialize the PDFDocument to bytes (a Uint8Array)
        return await flat(await pdfDoc.save())
    }
    catch (err) {
        console.error(err)
    }
}

async function flat(formPdfBytes) {
    const pdfDoc = await PDFDocument.load(formPdfBytes);
    const form = pdfDoc.getForm();
    form.flatten();
    return await pdfDoc.save()
}

const boolFields = ['isCooperativeMember', 'isHMOMember', 'isHasAdditionalRevenue', 'isIsraeliResident', 'isPartnerRevenue'];
const enumFields = ['maritalStatusID', 'revenueTypeID', "genderID"];
const HMO = [{ value: 1, label: "klalit" }, { value: 3, label: "maccabi" }, { value: 4, label: "meuchedet" }, { value: 2, label: "leumit" }]

function extractSpecialFields(data) {
    if (!isMarried(data.maritalStatusID)) {
        delete data.isPartnerRevenue;
    }

    for (let i in boolFields) {
        const key = boolFields[i];
        if (data.hasOwnProperty(key)) {
            const value = data[key];
            const newKey = key + (value ? "_1" : "_0");
            data[newKey] = true;
        }

    }
    for (let i in enumFields) {
        const key = enumFields[i];
        const value = data[key];
        const newKey = key + "_" + value;
        data[newKey] = value;
    }

    for (let i in data.employeeChildrens) {
        let childData = data.employeeChildrens[i];
        for (let key in childData) {
            data[key + "_" + (+i + 1)] = childData[key];
        }
    }
    data["HMO"] = data.isHMOMember && i18n.t(HMO.find(hmo => hmo.value === +data.hmoID).label);
    data["dateNow"] = new Date();
    data['deductionsPortfolio'] = data.deductionsPortfolio.slice(1);
    data["partnerPassportNo"] = data.partnerPassport;
    data["city"] = data.city.replace('(', '$').replace(')', '(').replace('$', ')');
    data["taxYear"] = data.taxYear.getFullYear();
}

function download(data, filename, type) {
    var file = new Blob([data], { type: type });
    var a = document.createElement("a"),
        url = URL.createObjectURL(file);
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    }, 0);
}

export { generatePdf, download };