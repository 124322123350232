import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import DatePicker from "./ui/DatePicker";

export default () => {

    const { t } = useTranslation();

    return (
        <Grid container spacing={2} >
            <Grid item md={6} xs={12}>
                <Typography gutterBottom>{t("taxCreditPoints.4.from")}</Typography>
                <DatePicker name="newImmigrantDateFrom" label={t("taxCreditPoints.4.from")} required />
            </Grid>
            <Grid item md={6} xs={12}>
                <Typography gutterBottom>{t("taxCreditPoints.4.to")}</Typography>
                <DatePicker name="newImmigrantNoRevenueEndDate" label={t("taxCreditPoints.4.to")} />
            </Grid>
        </Grid>
    );
}