import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import AutoComplete, { AutocompleteOption } from "../ui/AutoComplete";
import TextField from "../ui/TextField";
import { months, days } from '../../utils/hebrewDates'
import config from "../../config";
import RadioButton from "../ui/RadioButton";
import { useFormContext } from "react-hook-form";


export default () => {
    const [options, setOptions] = useState<AutocompleteOption[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const { t } = useTranslation();
    const { watch, clearErrors, setValue } = useFormContext();

    const changePhoneYype = () => {
        setValue("emergencyPhone", '')
        clearErrors("emergencyPhone");
    }

    const initCountries = (() => {
        setLoading(true)
        fetch(`${config.apiCountryUrl}`)
            .then(response => response.json())
            .then(result => {
                const options = result.map((c: any) => ({
                    id: c.code,
                    label: c.nameEn.trim(),
                }))
                setOptions(options)
                return options;
            }).then(() => {
                setLoading(false)
            })
    })

    useEffect(() => {
        initCountries();
    }, [])

    return (
        <Grid item container spacing={2} >
            <Grid item md={6} xs={12}>
                <AutoComplete
                    options={options}
                    name="residenceCountry"
                    label={t("countryOfResidence")}
                    getValue={(o) => o?.id}
                    loading={loading}
                ></AutoComplete>
            </Grid>
            <Grid item md={6} xs={12}>
                <AutoComplete
                    options={options}
                    name="birthCountry"
                    label={t("countryOfBirth")}
                    getValue={(o) => o?.id}
                    loading={loading}
                ></AutoComplete>
            </Grid>
            <Grid item md={6} xs={12}>
                <AutoComplete
                    options={days}
                    label={t("hebrewBirthDate(Day)")}
                    name='hebrewBirthDay'
                    getValue={(o: string) => o}
                ></AutoComplete>
            </Grid>
            <Grid item md={6} xs={12}>
                <AutoComplete
                    options={months}
                    label={t("hebrewBirthDate(Month)")}
                    name='hebrewBirthMonth'
                    getValue={(o: string) => o}
                ></AutoComplete>
            </Grid>
            <Grid item md={12} xs={12}>
                <TextField name="referredTo" label={t("whoReferredYou")} required />
            </Grid>
            <Grid item md={12} xs={12}>
                <TextField name="emergencyContact" label={t("emergencyContact")} required />
            </Grid>
            <Grid item xs={12} md={12} >
                <RadioButton required name="isIsraeliPhone" title="isIsrealiPhone" options={[{ value: 1, label: "Yes" }, { value: 2, label: "No" }]} isRow={true} onChange={changePhoneYype} />
            </Grid>
            {
                (watch("isIsraeliPhone") == 1) &&
                <Grid item md={12} xs={12}>
                    <TextField name="emergencyPhone" label={t("emergencyPhone") + " - " + t("phoneNote")} required />
                </Grid>
            }
            {
                (watch("isIsraeliPhone") == 2) &&
                <Grid item md={12} xs={12}>
                    <TextField name="emergencyPhone" label={t("emergencyPhone") + " - " + t("phoneNote")} required placeholder={"(xxx) xxxxxxxxxx"} />
                </Grid>
            }


        </Grid>
    );
}