import { Stack, Typography } from '@mui/material'

export default function AccessDenied() {

    return (
        <Stack mt={8} alignItems='center'>
        <Typography variant='h4'>.אינך מורשה לבצע את הפעולה</Typography>
        </Stack>
    )

}
