export const parseBool = (s: any) => String(s).toLowerCase() === "true";

export const getExtension = (file: any) => file.name.split(".").pop().toLowerCase();

export const resolve = (path: string, obj: any) => {
    return path.split('.').reduce(function (prev, curr) {
        return prev ? prev[curr] : '';
    }, obj)
}

export const isResident = ({ identityNo}: any) => {
    return (identityNo && !identityNo.startsWith('77'));
}