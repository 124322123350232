import React from "react";
import Loading from "../Loading";
import Form from "./Form";
import { useData } from "../../utils/useData";
import Error from "../Error";
import { useParams } from "react-router-dom";

export default () => {
    
    const { employeeId } = useParams<{ employeeId: string }>()
    const getUrl = `OnBoarding/GetOnBoarding?employeeID=${employeeId}`
    const postUrl=`OnBoarding/UpdateOnBoarding`

    const { data, loading, error, submit } = useData(getUrl,postUrl);

    if (loading) {
        return <Loading />
    }

    if (error) {
        return <Error />
    }

    return <Form data={data} submit={submit} />

}