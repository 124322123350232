import React from "react"
import { Autocomplete, CircularProgress, InputAdornment, TextField } from "@mui/material"
import { Controller, useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { resolve } from "../../utils/utils"

export interface AutocompleteOption {
    id: string,
    label: string
}

export interface SearchProps {
    options: any[]
    name: string,
    label: string,
    getValue: ((o: any) => any)
    loading?: boolean,
    onChange?: (value: any | null) => void;
    required?:boolean
}

export default (props: SearchProps) => {
    const { t } = useTranslation();
    const { control, formState: { errors } } = useFormContext()
    const error = resolve(props.name, errors);

    return (
        <Controller
            control={control}
            name={props.name}
            render={({ field: { ref, onChange, ...field } }) => {
                return (
                    <Autocomplete
                        {...field}
                        disablePortal
                        fullWidth
                        clearOnBlur={false}
                        openOnFocus
                        noOptionsText={t('no options')}
                        options={props.options}
                        value={props.options.find(o => props.getValue(o) === field.value) || null}
                        getOptionLabel={option => option.label || option}
                        onChange={((event: any, newValue: any | null) => {
                            onChange(props.getValue(newValue));
                            props.onChange?.(newValue)
                        })}
                        renderInput={(params) =>
                            <TextField {...params}
                                required={props.required===false?false:true}
                                label={props.label}
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: props.loading ? (
                                        <InputAdornment position="start">
                                            <CircularProgress size={24} />
                                        </InputAdornment>
                                    ) : params.InputProps.startAdornment
                                }}
                                helperText={error?.message}
                                error={!!error}
                            />
                        }
                    />
                )
            }
            }
        />);
}