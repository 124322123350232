import { createTheme, Theme } from '@mui/material/styles';
import i18n from './i18n';

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme { }
}

export const theme = createTheme({
  direction: i18n.t('dir'),
  palette: {
    primary: {
      main: '#005A70'
    }
  },


});
