import {CalendarPickerView, DatePicker} from '@mui/x-date-pickers'
import { useFormContext, Controller } from "react-hook-form";
import TextField from "./TextField";

export interface DesktopDatePickerProps {
    name: string;
    label: string;
    views?: CalendarPickerView[];
    format?: string;
    readOnly?: boolean;
    minDate?: Date;
    maxDate?: Date;
    required?: boolean;
    open?:boolean
    onChange?:()=>void
}

export default ({ format = "dd/MM/yyyy", ...props }: DesktopDatePickerProps) => {

    const { control } = useFormContext();

    if (!props.name)
        throw new Error("name propety is missing. name is required to indentity fields in the form")


    return (
        <Controller
            control={control}
            name={props.name}
            render={({ field: { onChange, value } }) => (
                <DatePicker
                open={props.open}
                    value={value || null}
                    inputFormat={format}
                    {...props}
                    label={props.label + (props.required ? " *" : "")}
                    onChange={ (e) => {
                        onChange(e);
                        props.onChange?.()
                    } }
                    
                    renderInput={(params) => (<TextField  {...params} name={props.name}/>)}
                />)}
        />
    )
}