import * as yup from 'yup';
import "yup-phone-lite";
import i18n from '../i18n';
import './phone-number-validator'

const englishRegex = /^[A-Za-z ']+$/i;
const phoneRegex = /^[(]{1}[0-9]{1,10}[)]{1}[-\s/0-9 -]{7}[-\s/0-9 -]*[0-9]{1}$/

export default {
    birthCountry: yup
        .string().nullable().trim()
        .required(() => i18n.t('validations.required', { field: i18n.t('countryOfBirth') })),
    emergencyPhone: yup
        .string().nullable().trim()
        .required(() => i18n.t('validations.required', { field: i18n.t('emergencyPhone') }))
        .when(['isIsraeliPhone'], {
            is: (isIsraeliBank: any) => isIsraeliBank == 1,
            then: yup.string().nullable().trim().phoneNumber("IL", () => i18n.t("validations.phone")),
            otherwise: yup.string().nullable().trim().matches(phoneRegex, () => i18n.t('validations.phone', { field: i18n.t('emergencyPhone') }))
        }),
    residenceCountry: yup
        .string().nullable().trim()
        .required(() => i18n.t('validations.required', { field: i18n.t('countryOfResidence') })),
    emergencyContact: yup
        .string().nullable().trim()
        .required(() => i18n.t('validations.required', { field: i18n.t('emergencyContact') }))
        .matches(englishRegex, () => i18n.t('validations.inEnglish', { field: i18n.t('emergencyContact') })),
    hebrewBirthMonth: yup
        .string().nullable().trim()
        .required(() => i18n.t('validations.required', { field: i18n.t('hebrewBirthDate(Month)') })),
    hebrewBirthDay: yup
        .string().nullable().trim()
        .required(() => i18n.t('validations.required', { field: i18n.t('hebrewBirthDate(Day)') })),
    referredTo: yup
        .string().nullable().trim()
        .required(() => i18n.t('validations.required', { field: i18n.t('whoReferredYou') }))
        .matches(englishRegex, () => i18n.t('validations.inEnglish', { field: i18n.t('whoReferredYou') })),
    isIsraeliPhone: yup
        .string().nullable().trim()
        .required(() => i18n.t('validations.requiredField')),
};