import * as yup from 'yup';
import "./israeli-id-validator";
import './future-date-validator';

import i18n from '../i18n';
import { dateValidator, requiredDateValidator, personalNameValidator } from './validators';
import config from '../config';

const year =Number(config.taxYear)
const minDate = new Date(year - 100, 0, 1);

export default {
    partnerIdentityNo: yup.string().nullable().trim().notRequired().israeliIdentity(i18n.t('validations.identityNo'))
        .when(['maritalStatusID', 'partnerPassport'], {
            is: (maritalStatusID: number, partnerPassport: string) => maritalStatusID == 2 && (!partnerPassport || !partnerPassport.trim()),
            then: yup.string().nullable().trim().required(i18n.t('validations.oneOf', { field1: i18n.t('identity number'), field2: i18n.t('passport') }))
        }),
    partnerPassportState: yup.string().nullable().trim().notRequired().when(['partnerPassport'], {
        is: (passportNo: string) => passportNo,
        then: yup.string().nullable().trim().required(i18n.t('validations.required', { field: i18n.t('passportState') }))
    }),
    partnerFirstName: yup
        .string().nullable().trim()
        .when(['maritalStatusID'], {
            is: (maritalStatusID: number) => maritalStatusID == 2,
            then: personalNameValidator('firstName', 'partnerIdentityNo')
        }),
    partnerLastName: yup
        .string().nullable().trim()
        .when(['maritalStatusID'], {
            is: (maritalStatusID: number) => maritalStatusID == 2,
            then: personalNameValidator('lastName', 'partnerIdentityNo')
        }),
    partnerBirthDate: dateValidator()
        .when(['maritalStatusID'], {
            is: (maritalStatusID: number) => maritalStatusID == 2,
            then: requiredDateValidator('birthDate')
                .notFutureDate(i18n.t('birthDate'))
                .min(minDate, i18n.t('validations.upTo100')),

        }),
    partnerImmigrationDate: dateValidator()
        .notFutureDate(i18n.t('aliyaDate'))
        .min(minDate, i18n.t('validations.upTo100')),
    isPartnerRevenue: yup.boolean().nullable().notRequired().when(["isPartnerWorkRevenue", "isPartnerOtherRevenue"], {
        is: (isPartnerWorkRevenue: boolean, isPartnerOtherRevenue: boolean) => {
            return !isPartnerWorkRevenue && !isPartnerOtherRevenue;
        },
        then: yup.boolean().nullable().isFalse(i18n.t('validations.revenueType'))
    }),
};