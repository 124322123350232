import { Grid } from "@mui/material"
import { useFormContext } from "react-hook-form";
import { parseBool } from '../utils/utils';
import RadioButton from './ui/RadioButton';

export default () => {
    const { watch, setValue, clearErrors } = useFormContext();


    const onChange = (value: any) => {
        if (!parseBool(value)) {
            setValue("hmoID", null);
            clearErrors("hmoID");
        }
    }


    return (
        <>
            <Grid item xs={6} md={6} lg={2}>
                <RadioButton required name="isHMOMember" title="hmoMember" options={[{ value: true, label: "yes" }, { value: false, label: "no" }]} onChange={onChange} />
            </Grid>
            {
                parseBool(watch("isHMOMember")) &&
                <Grid item xs={6} md={6} lg={2}>
                    <RadioButton required name="hmoID" title="hmo" options={[{ value: 1, label: "klalit" }, { value: 3, label: "maccabi" }, { value: 4, label: "meuchedet" }, { value: 2, label: "leumit" }]} />
                </Grid>
            }
        </>
    )
}