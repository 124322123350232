import { Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import InfoIcon from '@mui/icons-material/InfoOutlined';

export interface Props {
    text: string;
    tooltip: string;
}

export default ({ text, tooltip }: Props) => {
    const { t, i18n } = useTranslation();

    const translatedTooltip = t(tooltip);
    return (
        <Typography>{t(text)}
            {
                i18n.exists(tooltip) &&
                <Tooltip   title={translatedTooltip} leaveTouchDelay={5000}  enterTouchDelay={0} placement="left-start" style={{pointerEvents: "all"}} onClick={(e) => {e.stopPropagation(); e.preventDefault();}}>
                    <InfoIcon fontSize="small" />
                </Tooltip>
            }           
        </Typography>
    )
};