import React, {ReactType} from 'react'
import Grid from '@mui/material/Grid'
import makeStyles from '@mui/styles/makeStyles';
import {Box, Typography} from '@mui/material'
import { Theme } from '@mui/material/styles';


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(10)
  },
  large: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  child: {
    marginTop: theme.spacing(5)
  }
}))

interface MainContainerProps extends React.PropsWithChildren<{}> {
  isChild?: boolean
  large?: boolean
  title?: string
  subtitle?: string | ReactType
}

function Title({
  mainTitle,
  Subtitle
}: {
  mainTitle?: string
  Subtitle?: string | ReactType
}) {
  return (
    <Box mb={4} mt={4}>
      <Typography variant="h5">
        {mainTitle}
        {Subtitle && (
          <Typography display="inline" variant="body1">
            {typeof Subtitle === 'string' ? Subtitle : <Subtitle />}
          </Typography>
        )}
      </Typography>
    </Box>
  )
}

export default function MainContainer(props: MainContainerProps) {
  const classes = useStyles()

  return (
    <Grid
      container
      justifyContent="space-around"
      className={props.isChild ? classes.child : classes.root}
    >
      <Grid
        item
        xs={props.large ? 12 : 11}
        className={props.large ? classes.large : ''}
      >
        {props.title && (
          <Title mainTitle={props.title} Subtitle={props.subtitle} />
        )}
        {props.children}
      </Grid>
    </Grid>
  )
}
