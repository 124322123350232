import * as yup from 'yup';
import "yup-phone-lite";
import "./israeli-id-validator"

import i18n from '../i18n';
import { dateValidator, personalNameValidator, requiredDateValidator, requiredFileValidator } from './validators';
import { isResident, parseBool } from '../utils/utils';
import config from '../config';

const year = Number(config.taxYear)
const minDate = new Date(year - 100, 0, 1);
const notEnglishRegex = /^[^a-zA-Z]+$/;
const AtLeastOneNumber = /.*[0-9].*/
const onlyNumbersRegex = /^\d*$/
export default {
    identityNo: yup.string().nullable().trim().notRequired().israeliIdentity(i18n.t('validations.identityNo'))
        .when(['passportNo'], {
            is: (passportNo: string) => !passportNo || !passportNo.trim(),
            then: yup.string().nullable().trim().required(i18n.t('validations.oneOf', { field1: i18n.t('identity number'), field2: i18n.t('passport') }))
        }),
    passportState: yup.string().nullable().trim().notRequired().when(['passportNo'], {
        is: (passportNo: string) => passportNo,
        then: yup.string().nullable().trim().required(i18n.t('validations.required', { field: i18n.t('passportState') }))
    }),
    firstName: personalNameValidator('firstName', 'identityNo'),
    lastName: personalNameValidator('lastName', 'identityNo'),
    birthDate: requiredDateValidator('birthDate')
        .notFutureDate(i18n.t('birthDate'))
        .min(minDate, i18n.t('validations.upTo100')),
    immigrationDate: dateValidator()
        .notFutureDate(i18n.t('aliyaDate'))
        .min(minDate, i18n.t('validations.upTo100')),
    email: yup
        .string().nullable().trim()
        .required(i18n.t('validations.required', { field: i18n.t('email') }))
        .email(i18n.t('validations.email')),
    phone: yup
        .string().nullable().trim()
        .transform((value) => value ? value : '')
        .phone('IL', i18n.t('validations.phone')),
    cellPhone: yup
        .string().nullable().trim()
        .when(['phone'], {
            is: (phone: string) => !phone || phone.length === 0,
            then: yup
                .string().nullable().trim()
                .phone('IL', i18n.t('validations.phone'))
                .required(i18n.t('validations.oneOf', { field1: i18n.t('phone'), field2: i18n.t('Mobile Phone Number') })),
            otherwise: yup.string().nullable().trim()
                .transform((value) => value ? value : '')
                .phone('IL', i18n.t('validations.phone')),
        }),


    city: yup
        .string().nullable().trim()
        .required(i18n.t('validations.required', { field: i18n.t('city') })),
    hmoID: yup
        .string().nullable().trim()
        .when(['isHMOMember'], {
            is: (isHMOMember: any) => parseBool(isHMOMember),
            then: yup.string().nullable().trim().required(i18n.t('validations.required', { field: i18n.t('hmo') }))
        }),
    isHMOMember: yup
        .string().nullable().trim()
        .required(i18n.t('validations.required', { field: i18n.t('hmoMember') })),
    isCooperativeMember: yup
        .string().nullable().trim()
        .required(i18n.t('validations.required', { field: i18n.t('isCooperativeMember') })),
    isIsraeliResident: yup
        .boolean().nullable()
        .required(i18n.t('validations.required', { field: i18n.t('isIsraeliResident') }))
        .when(["identityNo"], {
            is: (identityNo: string) => {
                return isResident({ identityNo })
            },
            then: yup.boolean().nullable().isTrue(i18n.t('validations.isIsraeli')),
            otherwise: yup.boolean().nullable().isFalse(i18n.t('validations.isNotIsraeli')),
        }),

    houseNumber: yup
        .string().nullable().trim()
        .required(i18n.t('validations.required', { field: i18n.t('houseNumber') }))
        .matches(notEnglishRegex, () => i18n.t('validations.notEnglish', { field: i18n.t('houseNumber') }))
        .matches(AtLeastOneNumber, () => i18n.t('validations.oneNumber', { field: i18n.t('houseNumber') })),
    street: yup
        .string().nullable().trim()
        .required(i18n.t('validations.required', { field: i18n.t('streetName') }))
        .matches(notEnglishRegex, () => i18n.t('validations.notEnglish', { field: i18n.t('streetName') })),
    zipCode: yup
        .string().nullable(true).trim()
        .transform((value) => value ? value : '').notRequired()
        .matches(onlyNumbersRegex, () => i18n.t('validations.onlyNumbers', { field: i18n.t('zipCode') })),
    // .required(i18n.t('validations.required', { field: i18n.t('zipCode') })),
    genderID: yup
        .string().nullable().trim()
        .required(i18n.t('validations.required', { field: i18n.t('gender') })),
    maritalStatusID: yup
        .string().nullable().trim()
        .required(i18n.t('validations.required', { field: i18n.t('maritalStatus') })),
    identityCardFile: requiredFileValidator('identityCard')
};