import { Button, FormControl, FormHelperText, FormLabel, Grid } from '@mui/material';
import BackspaceIcon from '@mui/icons-material/Backspace';
import { useState } from 'react'
import { useTranslation } from 'react-i18next';
import SignatureCanvas from 'react-signature-canvas'
import { useFormContext } from 'react-hook-form'
import { resolve } from '../utils/utils';

const name = 'signature'

export default () => {
    const { setValue, formState: { errors }, clearErrors } = useFormContext();

    const { t } = useTranslation();

    const [pad, setPad] = useState<any>(null);

   const width = window.innerWidth * 0.81;

    const onSign = () => {
        clearErrors(name);
        setValue(name, pad.getTrimmedCanvas().toDataURL('image/png'));
    }

    const clear = () => {
        pad.clear();
        setValue(name, null);
    }

    const error = resolve(name, errors);

    return (
        <FormControl error={!!error}>
            <FormLabel>{t("sign note")}</FormLabel>
            <Grid container>
                <Grid item lg={11} sx={{ border: 1 }} >
                    <SignatureCanvas
                        ref={setPad}
                        onEnd={onSign}
                        canvasProps={{ height:200,width:width }}
                    />
                </Grid>
                <Grid item lg={1}>
                    <Button
                        onClick={clear}>
                        <BackspaceIcon fontSize="small" />
                        {t("clear")}
                    </Button>
                </Grid>
                <FormHelperText error={!!error} >{error?.message}</FormHelperText>
            </Grid>
        </FormControl>
    );
}