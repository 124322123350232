import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { useTranslation } from 'react-i18next';





const EmotionRtl = ({ children }: any) => {

  const { t } = useTranslation();

  const dir = t("dir");
  const isRtl = dir === "rtl";


  if (isRtl) {
    // Create rtl cache
    const cacheRtl = createCache({
      key: 'muirtl',
      stylisPlugins: [rtlPlugin],
    });

    document.body.dir = dir;

    return <CacheProvider value={cacheRtl}>{children}</CacheProvider>;
  }
  return children;
}

export default EmotionRtl;