import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import DatePicker from "./ui/DatePicker";
import TextField from "./ui/TextField";

export default () => {

    const { t } = useTranslation();

    return (
        <Grid container spacing={2}>
            <Grid item  md={6} xs={12}>
            <DatePicker name="qualifyingLocalityDateFrom" label={t("taxCreditPoints.3.from")} required />
            <Typography gutterBottom>{t("taxCreditPoints.3.extra")}</Typography>
            <TextField name="qualifyingLocality" label={t("locality")} required />
            </Grid>
        </Grid>
    );
}