import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Grid, IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Checkbox from "./ui/Checkbox";
import DatePicker from "./ui/DatePicker";
import TextField from "./ui/TextField";
import { useFormContext, useFieldArray } from "react-hook-form";
import { useConfirmDialog } from 'react-mui-confirm';
import { isResident } from '../utils/utils';

const newChild = {
    childName: "",
    childBirthDate: null,
    childIdentityNo: "",
    isHold: true, isBIL: true
};

interface ChildrenInfoProps{
    setChildrenAge:()=>void
}
export default ({setChildrenAge}:ChildrenInfoProps) => {
    const { t } = useTranslation();
    const { control, trigger, getValues } = useFormContext();
    const { fields, append, remove } = useFieldArray({
        control,
        name: "employeeChildrens"
    });
    const confirm = useConfirmDialog();
    const removeChild = (child: any, index: number) => {
        confirm({
            title: t("deleteConfirm"),
            description: t("deleteConfirmMessage", { entity: child.childName }),
            cancelButtonText: t("no"),
            confirmButtonText: t("yes"),
            onConfirm: () => {
                remove(index);
                revalidate();
               
            }
        });

    }
    const revalidate = () => {
        setChildrenAge()
        trigger('isChildrenHold');
        trigger('isToddlerChildren');
    }



    return (<>
        {fields.map((field, index) => {
            return (<>
                <Grid  container spacing={2} key={field.id} m={{xs:0,sm:0, md:4}} pr={{xs:2,sm:2, md:0}}      mt={0}>
                    <Grid item container md={6}  spacing={2}>
                        <Grid item container xs={12}>
                            <Grid item  xs={11}>
                                <Typography>{t("child")} {index + 1}:</Typography>
                            </Grid>
                            <Grid item xs={1} textAlign="end">
                                <IconButton color="primary" aria-label={t("delete")} component="span" onClick={() => { removeChild(field, index) }} >
                                    <DeleteIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField name={`employeeChildrens.${index}.childName`} label={t("Child Name")} required />
                        </Grid>
                        {isResident(getValues()) &&
                            <Grid item xs={12}>
                                <TextField trim name={`employeeChildrens.${index}.childIdentityNo`} label={t("identity number")} required />
                            </Grid >
                        }
                        <Grid item xs={12}>
                            <DatePicker name={`employeeChildrens.${index}.childBirthDate`} label={t("birthDate")} required onChange={setChildrenAge} />
                        </Grid>
                        <Grid item xs={12}>
                            <Checkbox name={`employeeChildrens.${index}.isHold`} label={t("in my possession")} onChange={revalidate} />
                            <Checkbox name={`employeeChildrens.${index}.isBIL`} label={t("allowance")} onChange={revalidate} />
                        </Grid>
                    </Grid>
                </Grid>
            </>
            )
        })}
        <Button color="primary" onClick={() => {
            append({ ...newChild });
            revalidate();
        }
        }>{t("add child")}</Button>
    </>);


}