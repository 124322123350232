import ArmyService from "./ArmyService";
import ChildrenSummary from "./ChildrenSummary";
import DisabledChildren from "./DisabledChildren";
import NewImmigrant from "./NewImmigrant";
import QualifyingLocation from "./QualifyingLocation";
import TaxCreditPoint from "./TaxCreditPoint";
import ToddlerChildrenSummary from "./ToddlerChildrenSummary";

interface taxCreditPointsProps{
    setChildrenAge:()=>void
    setLoadingFile:any
}
export default ({setChildrenAge,setLoadingFile}:taxCreditPointsProps) => {

    return (<ol>
        <TaxCreditPoint index={1} name="isIsraeliResident"  setLoadingFile={setLoadingFile}/>
        <TaxCreditPoint index={2} name='isDisabled' setLoadingFile={setLoadingFile} />
        <TaxCreditPoint index={3} name="isQualifyingLocality" setLoadingFile={setLoadingFile}>
            <QualifyingLocation />
        </TaxCreditPoint>
        <TaxCreditPoint index={4} name="isNewImmigrant"  setLoadingFile={setLoadingFile}>
            <NewImmigrant />
        </TaxCreditPoint>
        <TaxCreditPoint index={5} name="isPartnerNoRevenue" setLoadingFile={setLoadingFile} />
        <TaxCreditPoint index={6} name="isSingleParent"  setLoadingFile={setLoadingFile}/>
        <TaxCreditPoint index={7} name="isChildrenHold" dependsOn={["isToddlerChildren", "isSingleParentHoldChildren"]} setLoadingFile={setLoadingFile}>
            <ChildrenSummary setChildrenAge={setChildrenAge} />
        </TaxCreditPoint>
        <TaxCreditPoint index={8} name="isToddlerChildren" dependsOn="isSingleParentHoldChildren">
            <ToddlerChildrenSummary setChildrenAge={setChildrenAge} />
        </TaxCreditPoint>
        <TaxCreditPoint index={9} name="isSingleParentHoldChildren" dependsOn={["isChildrenHold", "isToddlerChildren"]}setLoadingFile={setLoadingFile}/>
        <TaxCreditPoint index={10} name="isChildrenNotHold"setLoadingFile={setLoadingFile} />
        <TaxCreditPoint index={11} name="isHasDisabledChild" setLoadingFile={setLoadingFile}>
            <DisabledChildren />
        </TaxCreditPoint>
        <TaxCreditPoint index={12} name="isPayingAlimony"setLoadingFile={setLoadingFile} />
        <TaxCreditPoint index={13} name="is16to18"setLoadingFile={setLoadingFile} />
        <TaxCreditPoint index={14} name="isReleasedSoldier"setLoadingFile={setLoadingFile} >
            <ArmyService />
        </TaxCreditPoint>
        <TaxCreditPoint index={15} name="isHasDegree" setLoadingFile={setLoadingFile}/>
    </ol>);
}