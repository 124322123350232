import { useTranslation } from "react-i18next";
import RadioButton from "./ui/RadioButton";
import { useFormContext } from 'react-hook-form';
import RevenueTypes from "./RevenueTypes";
import { parseBool } from "../utils/utils";
import Checkbox from "./ui/Checkbox";
import { Typography } from "@mui/material";
import InfoLabel from "./ui/InfoLabel";


export default () => {
    const { watch, setValue } = useFormContext();
    const { t } = useTranslation();
    const types = [
        {
            value: false,
            label: t("noOther")
        },
        {
            value: true,
            label: t("hasOther")
        }
    ];

    const onChange = (value: any) => {
        if(!parseBool(value)) {
            setValue("isUseTaxCreditPoints", false)
            setValue("isDontUseTaxCreditPoints", false)
            setValue("isNoProvisionsStudyFund", false)
            setValue("isNoProvisionsBenefits", false)
            setValue("isAdditionalRevenueMonthly", false)
            setValue("isAdditionalRevenueExtra", false)
            setValue("isAdditionalRevenuePartial", false)
            setValue("isAdditionalRevenueDaily", false)
            setValue("isAdditionalRevenueAllowence", false)
            setValue("isAdditionalRevenueScholarship", false)
        }
    }

    return (
        <>      
            <RadioButton name="isHasAdditionalRevenue" options={types} onChange={onChange}/>
            {parseBool(watch("isHasAdditionalRevenue")) && <>
                <RevenueTypes dependsOn="isHasAdditionalRevenue" />
                <Typography variant="subtitle2">{t("otherRevenueTitle")}</Typography>
                <Typography variant="subtitle2">{t("taxCreditTitle")} *</Typography>
                <Checkbox name="isUseTaxCreditPoints" label={<InfoLabel text="credit.1.title" tooltip="credit.1.tooltip" />} />
                <Checkbox name="isDontUseTaxCreditPoints" dependsOn="isUseTaxCreditPoints" label={<InfoLabel text="credit.2.title" tooltip="credit.2.tooltip" />} />
                <Typography variant="subtitle2">{t("allowanceTitle")}</Typography>
                <Checkbox name="isNoProvisionsStudyFund" label={<InfoLabel text="credit.3.title" tooltip="credit.3.tooltip" />} />
                <Checkbox name="isNoProvisionsBenefits" label={<InfoLabel text="credit.4.title" tooltip="credit.4.tooltip" />} />
            </>}
        </>

    );
}

