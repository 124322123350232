import { isValidPhoneNumber } from 'libphonenumber-js';
import { CountryCode } from 'libphonenumber-js/types';
import { Message } from 'yup/lib/types';
import * as Yup from 'yup';


declare module "yup" {
    export interface StringSchema {
        /**
         * Check for pisraeli id number validity.
         *
         * @param {String} [errorMessage=DEFAULT_MESSAGE] The error message to return if the validation fails.
         */
        phoneNumber(countryCode?: CountryCode, errorMessage?: Message<{}>): StringSchema;
    }
}

const isValidCountryCode = (countryCode: any): boolean =>
    typeof countryCode === "string" &&
    countryCode.length === 2;

Yup.addMethod(Yup.string,
    "phoneNumber",
    function (countryCode?: CountryCode, message?: Message<{}>) {
        countryCode = "IL"
        return this.test("phoneNumber", '', function (value: any, countryCode: any) {
            const { path, createError } = this;
            return isValidPhone(value, countryCode) || createError({ path, message });
        });
    });

export function isValidPhone(value: string, countryCode: CountryCode) {
    if (!isValidCountryCode(countryCode)) {
        countryCode = "IL";
    }
    try {
        if (value === undefined || value === "") {
            return true;
        }
        return isValidPhoneNumber(value, countryCode);
    } catch {
        return false;
    }
}

