import { useFormContext } from "react-hook-form";
import RadioButton from "./ui/RadioButton"

export default ({ setChildrenAge }: any) => {
    const { trigger } = useFormContext();

    const onChange = () => {
        setChildrenAge()
        trigger('isChildrenHold');
        trigger('isToddlerChildren');

    }
    return (
        <RadioButton required name="genderID" title="gender" options={[{ value: 1, label: "male" }, { value: 2, label: "female" }]} onChange={onChange} />
    )
}