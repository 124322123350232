import { Link, Typography, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

export default () => {
    const { t } = useTranslation();
    const { isBack } = useParams<{ isBack: string }>()
    return (
        <Grid container mt={6} justifyContent="center" alignItems="center">
            <Grid item xs={12}>
                <Typography textAlign="center">{t("thankYou")}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography textAlign="center">{t("dataSaved")}</Typography>
            </Grid>
            {isBack === 'true' && <Link href='/'>{t("backToEdit")}</Link>}
        </Grid>);
};