import React, { useEffect, useState } from "react"
import { Grid } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import config from "../config";
import TextField from "./ui/TextField";
import AutoComplete, { AutocompleteOption } from "./ui/AutoComplete";

export default () => {
    const { t } = useTranslation();
    const { setValue } = useFormContext();
    const [options, setOptions] = useState<AutocompleteOption[]>([])

    const initCities = (() => {
        fetch(`${config.apiCityUrl}`)
            .then(response => response.json())
            .then(({ result }: any) => {
                const options = result.records.map((c: any) => ({
                    id: c.שם_ישוב_לועזי.trim(),
                    label: c.שם_ישוב.trim().replace('(', '$').replace(')', '(').replace('$', ')'),
                }))
                setOptions(options)
                return options;
            })
    })

    useEffect(() => {
        initCities();
    }, [])

    const onChangeCityName = ((newValue: AutocompleteOption | null) => {
        setValue('cityEn', newValue?.id)
    })

    return (
        <Grid item container spacing={2} >
            <Grid item md={6} xs={12}>
                <AutoComplete
                    options={options}
                    name='city'
                    label={t("city")}
                    onChange={onChangeCityName}
                    getValue={(o) => o?.label}
                ></AutoComplete>
            </Grid>
            <Grid item md={6} xs={12}>
                <TextField
                    required
                    name="street"
                    label={t("streetName")}
                /> </Grid>
            <Grid item md={6} xs={12}>
                <TextField
                    required
                    name="houseNumber"
                    label={t("houseNumber")}
                /> </Grid>
            <Grid item md={6} xs={12}>
                <TextField
                    name="zipCode"
                    label={t("zipCode")}
                />
            </Grid>
        </Grid>
    );
}