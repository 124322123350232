import { useEffect, useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { Stack, Typography, TextField, Alert } from '@mui/material'
import config from '../../config'
import { useAuth } from '../../context/Auth'

export default function Reports() {

    const { fetchWithUser } = useAuth()

    const [reportNumber, setReportNumber] = useState<number>(0)
    const [disabled, setDisabled] = useState<boolean>(true)
    const [loading, setLoading] = useState<boolean>(false)
    const [showAlert, setShowAlert] = useState<boolean>(false)
    const [isSucceed, setIsSucceed] = useState<boolean>(false)

    useEffect(() => {
        const timeId = setTimeout(() => {
            setShowAlert(false)
        }, 5000)

        return () => {
            clearTimeout(timeId)
        }
    }, [showAlert]);

    const onChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
        setReportNumber(Number(e.target.value))
        e.target.value === '' ? setDisabled(true) : setDisabled(false)
    }

    const sendReports = () => {
        setLoading(true)
        fetchWithUser(reportNumber > 0 ?
            config.apiUrl +
            `/Form101/SendMonthlyReport/?fromXmlID=${reportNumber}` :
            config.apiUrl + `/Form101/SendMonthlyReport`,
            {
                method: 'POST',
                body: JSON.stringify({
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        )
            .then(res => {
                res.json()
                res.ok ?
                    setIsSucceed(true) : setIsSucceed(false)
            })
            .then(() => {
                setLoading(false)
                setShowAlert(true)
            })
            .catch(e => {
                setShowAlert(true)
                setIsSucceed(false)
                console.log(e)
            })
        setShowAlert(false)
    }

    return (
        <>
            <Stack mt={8} alignItems="center" justifyContent="center">
                <Stack
                    alignItems="center"
                    justifyContent="center"
                    height="100%"
                    spacing={3}
                >
                    <Typography variant='h5'>קבלת דו"חות 101</Typography>
                    <LoadingButton onClick={sendReports} color="primary" variant="contained" loading={loading && disabled} loadingPosition="end" fullWidth disabled={!disabled}  >
                        שליחת דו"ח מעודכן
                    </LoadingButton>
                    <Typography mt={4}>לשליחת דו"ח ממספר אחר</Typography>
                    <TextField InputProps={{ inputProps: { min: 1 } }} size='small' fullWidth type='number' label='ממספר דו"ח' onChange={onChangeText}></TextField>
                    <LoadingButton onClick={sendReports} loading={loading && !disabled} loadingPosition="end" color="primary" variant="contained" fullWidth disabled={disabled}  >
                        המשך
                    </LoadingButton>
                </Stack>
                <Stack mt={10} alignItems='flex-end'>
                    {showAlert && <Alert severity={isSucceed ? "success" : 'error'}>{isSucceed ? 'המייל נשלח בהצלחה, בדוק את תיבת המייל שלך בדקות הקרובות' : 'ארעה שגיאה אנא פנה למנהל המערכת'}</Alert>}
                </Stack>
            </Stack>


        </>
    )

}
