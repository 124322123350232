import { isDate, isValid, parse } from 'date-fns';
import * as yup from 'yup';
import config from '../config';
import i18n from '../i18n';
import { isResident } from '../utils/utils';
export const requiredFileValidator = (label: string) => yup.string().nullable().required(i18n.t('validations.requiredFile', { field: i18n.t(label) }))

export const conditionalFileValidator = (dependency: string, label: string) => yup.string().nullable().notRequired()
    .when([dependency], {
        is: (condition: boolean) => condition,
        then: requiredFileValidator(label)
    });

export const requiredDateValidator = (label: string) => dateValidator(label)
    .required(i18n.t('validations.required', { field: i18n.t(label) }));

export const dateValidator = (label?: string) => yup.date()
    .transform((curr, originalValue) => {
        const parsedDate = isDate(originalValue)
            ? originalValue
            : parse(originalValue, "dd/MM/yyyy", new Date(Number(config.taxYear),1,1));

        if (isValid(parsedDate))
            return parsedDate;
        return originalValue == "" ? null : curr;
    })
    .nullable().default(undefined)
    .typeError(i18n.t('validations.dateError'));

export const isMarried = (maritalStatusID: any) => maritalStatusID == 2;

export const singleParentValidator = () => yup.boolean().nullable().notRequired().when(["maritalStatusID"], {
    is: (maritalStatusID: number) => {
        return isMarried(maritalStatusID); //married
    },
    then: yup.boolean().nullable().isFalse(i18n.t('validations.singleParent'))
});

export const nameValidator = (label: string) => yup.string().nullable().trim()
    .min(2, i18n.t('validations.minLength', { minLength: 2, field: i18n.t(label) }))
    .required(i18n.t('validations.required', { field: i18n.t(label) }))

const englishRegex = /^[A-Za-z \-']+$/i;
const hebrewRegex = /^[\u0590-\u05FF \-']+$/i

export const personalNameValidator = (nameLabel: string, passportNoLable: string) => yup.string().nullable().trim()
    .min(2, i18n.t('validations.minLength', { minLength: 2, field: i18n.t(nameLabel) }))
    .required(i18n.t('validations.required', { field: i18n.t(nameLabel) })).when([passportNoLable], {
        is: (identityNo: string) => {
            return isResident({ identityNo });
        },
        then: yup.string().nullable().trim().matches(hebrewRegex, i18n.t('validations.hebrewLetters', { field: i18n.t(nameLabel) })),
        otherwise: yup.string().nullable().trim().matches(englishRegex, i18n.t('validations.englishLetters', { field: i18n.t(nameLabel) }))
    })
