import * as yup from 'yup';
import config from '../config';
import i18n from '../i18n';
import { dateValidator, conditionalFileValidator, requiredDateValidator, singleParentValidator, isMarried } from './validators';

const year = Number(config.taxYear);
const minDate = new Date(year - 100, 0, 1);
const startOfYear = new Date(year, 0, 1);


export default {
    isDisabledFile: conditionalFileValidator('isDisabled', 'taxCreditPoints.2.file'),
    newImmigrantDateFrom: dateValidator()
        .when(['isNewImmigrant'], {
            is: (isNewImmigrant: boolean) => isNewImmigrant,
            then: requiredDateValidator('taxCreditPoints.4.from')
                .notFutureDate(i18n.t('taxCreditPoints.4.from'))
                .min(minDate, i18n.t('validations.upTo100')),
        }),
    newImmigrantNoRevenueEndDate: dateValidator()
        .min(startOfYear, i18n.t("validations.currentYear", { field: i18n.t("endDate")}))
        .notFutureDate(i18n.t('endDate')),
    isNewImmigrantFile: conditionalFileValidator('isNewImmigrant', 'taxCreditPoints.4.file'),
    qualifyingLocalityDateFrom: dateValidator()
        .notFutureDate(i18n.t('taxCreditPoints.3.from'))
        .when(['isQualifyingLocality'], {
            is: (isQualifyingLocality: boolean) => isQualifyingLocality,
            then: requiredDateValidator('startDate')
                .min(minDate, i18n.t('validations.upTo100'))
        }),
    qualifyingLocality: yup.string().nullable().trim().notRequired()
        .when(['isQualifyingLocality'], {
            is: (isQualifyingLocality: boolean) => isQualifyingLocality,
            then: yup.string().nullable().trim().required(i18n.t('validations.required', { field: i18n.t('locality') }))
        }),
    isQualifyingLocalityFile: conditionalFileValidator('isQualifyingLocality', 'taxCreditPoints.3.file'),
    isHasDisabledChildFile: conditionalFileValidator('isHasDisabledChild', 'taxCreditPoints.11.file'),
    disabledChildrenNumber: yup.number().nullable().default(undefined)
        .when(['isHasDisabledChild'], {
            is: (hasDisabledChild: boolean) => hasDisabledChild,
            then: yup.number().nullable().default(undefined).min(1, i18n.t('validations.min', { field: i18n.t('children.disabled'), min: 1 }))
                .typeError(i18n.t('validations.required', { field: i18n.t('children.disabled') }))
                .required(i18n.t('validations.required', { field: i18n.t('children.disabled') }))

        }),
    militaryServiceEndDate: dateValidator()
        .notFutureDate(i18n.t('army.endDate'))
        .when(['isReleasedSoldier'], {
            is: (isReleasedSoldier: boolean) => isReleasedSoldier,
            then: requiredDateValidator('army.endDate')
                .min(minDate, i18n.t('validations.upTo100'))
        }),
    militaryServiceStartDate: dateValidator()
        .notFutureDate(i18n.t('army.startDate'))
        .when(['isReleasedSoldier'], {
            is: (isReleasedSoldier: boolean) => isReleasedSoldier,
            then: requiredDateValidator('army.startDate')
                .min(minDate, i18n.t('validations.upTo100'))
        }),
    isChildrenNotHoldFile: conditionalFileValidator('isChildrenNotHold', 'taxCreditPoints.10.file'),
    isPayingAlimonyFile: conditionalFileValidator('isPayingAlimony', 'taxCreditPoints.12.file'),
    isReleasedSoldierFile: conditionalFileValidator('isReleasedSoldier', 'taxCreditPoints.14.file'),
    isHasDegreeFile: conditionalFileValidator('isHasDegree', 'taxCreditPoints.15.file'),
    isChildrenHold: yup.boolean().nullable().notRequired().test("holdChildren", "", function (value: any) {
        const { createError, path, parent } = this;
        const { employeeChildrens, isSingleParentHoldChildren, maritalStatusID, genderID, childrenNumberBorn, childrenNumber1to5, childrenNumber6to12,childrenNumber13to17, childrenNumber18 } = parent;
        if (value) {
            if (employeeChildrens.length == 0)
                return createError({ path, message: i18n.t('validations.noChildren') });

            if (!checkFamilyStatus7(maritalStatusID, genderID, isSingleParentHoldChildren, employeeChildrens))
                return createError({ path, message: i18n.t('validations.taxcredit7') });

            if (!+childrenNumberBorn && !+childrenNumber1to5 && !+childrenNumber6to12 && !+childrenNumber13to17 &&!+childrenNumber18) {
                return createError({ path, message: i18n.t('validations.children') });
            }
        }
        return true;
    }),
    isToddlerChildren: yup.boolean().nullable().notRequired().test("holdChildren", "", function (value: any) {
        const { createError, path, parent } = this;
        const { isChildrenHold, employeeChildrens, isSingleParentHoldChildren, maritalStatusID, genderID, toddlerChildNum1to5, toddlerChildNumBorn,toddlerChildNum6to12 } = parent;
        if (value) {
            if (isChildrenHold && !isSingleParentHoldChildren)
                return createError({ path, message: i18n.t('validations.notBoth') });

            if (employeeChildrens.length == 0)
                return createError({ path, message: i18n.t('validations.noChildren') });

            if (!checkFamilyStatus8(maritalStatusID, genderID, isSingleParentHoldChildren, employeeChildrens, isChildrenHold))
                return createError({ path, message: i18n.t('validations.taxcredit8') });

            if (!+toddlerChildNumBorn && !+toddlerChildNum1to5&&!+toddlerChildNum6to12) {
                return createError({ path, message: i18n.t('validations.children') });
            }
        }
        return true;
    }),
    toddlerChildNum1to5: yup.number().transform((c => !c ? 0 : c)).min(0, i18n.t('validations.min', { field: i18n.t('children.2'), min: 0 })),
    toddlerChildNumBorn: yup.number().transform((c => !c ? 0 : c)).min(0, i18n.t('validations.min', { field: i18n.t('children.1'), min: 0 })),
    toddlerChildNum6to12: yup.number().transform((c => !c ? 0 : c)).min(0, i18n.t('validations.min', { field: i18n.t('children.3'), min: 0 })),
    childrenNumber18: yup.number().transform((c => !c ? 0 : c)).min(0, i18n.t('validations.min', { field: i18n.t('children.5'), min: 0 })),
    childrenNumber6to12: yup.number().transform((c => !c ? 0 : c)).min(0, i18n.t('validations.min', { field: i18n.t('children.3'), min: 0 })),
    childrenNumber13to17: yup.number().transform((c => !c ? 0 : c)).min(0, i18n.t('validations.min', { field: i18n.t('children.4'), min: 0 })),
    childrenNumber1to5: yup.number().transform((c => !c ? 0 : c)).min(0, i18n.t('validations.min', { field: i18n.t('children.2'), min: 0 })),
    childrenNumberBorn: yup.number().transform((c => !c ? 0 : c)).min(0, i18n.t('validations.min', { field: i18n.t('children.1'), min: 0 })),
    isSingleParent: singleParentValidator(),
    isSingleParentHoldChildren: singleParentValidator().test("7or8on", '', function (value: any) {
        const { createError, path, parent } = this;
        const { isChildrenHold, isToddlerChildren } = parent;
        if (value) {
            if (!isChildrenHold && !isToddlerChildren)
                return createError({ path, message: i18n.t('validations.7or8') });
        }
        return true;
    })
};

function checkFamilyStatus8(maritalStatusID: any, genderID: any, isSingleParentHoldChildren: any, employeeChildrens: [], isChildrenHold: boolean) {
    if (isMarried(maritalStatusID) && genderID == 1) //married man
        return true;
    if (!isMarried(maritalStatusID) && genderID == 2 && employeeChildrens.some((c: any) => !c.isHold)) //non married woman
        return true;
    if (!isMarried(maritalStatusID) && isSingleParentHoldChildren)
        return true;
    return false;

}


function checkFamilyStatus7(maritalStatusID: any, genderID: any, isSingleParentHoldChildren: any, employeeChildrens: []) {
    if (isMarried(maritalStatusID) && genderID == 2) //married woman
        return true;
    if (!isMarried(maritalStatusID) && isSingleParentHoldChildren)
        return true;
    if (!isMarried(maritalStatusID) && employeeChildrens.some((c: any) => c.isBIL))
        return true;
    return false;
}
// function checkNotIsHoldChildren(employeeChildrens:any){
//     var a=employeeChildrens.some((e: { isHold: boolean; })=>e.isHold==false)
// console.log(a)
// }
