import { AxiosRequestTransformer } from "axios"
import { format } from "date-fns"

export const dateTransformer: AxiosRequestTransformer = data => {
  if (data instanceof Date) {
    // do your specific formatting here
    return format(data, "yyyy-MM-dd")
  }
  if (Array.isArray(data)) {
    return data.map(val => dateTransformer(val))
  }
  if (typeof data === "object" && data !== null) {
    return Object.fromEntries(Object.entries(data).map(([ key, val ]) =>
      [ key, dateTransformer(val) ]))
  }
  return data
}