import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import TextField from "./ui/TextField";

export default () => {

    const { t } = useTranslation();

    return (
        <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
            <TextField type="number" inputProps={{min:0}} label={t("children.disabled")} required name="disabledChildrenNumber" />
        </Grid>
    </Grid>
    );
}