import { FormHelperText } from "@mui/material";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useFileService from "../utils/file-storage";
import {parseBool } from "../utils/utils";
import Checkbox from "./ui/Checkbox";
import FileComponent from "./ui/File";

export default ({setLoadingFile}:{setLoadingFile:React.Dispatch<React.SetStateAction<boolean>>}) => {

    const { t } = useTranslation();
    const {  remove } = useFileService();
    const { getValues, setValue, watch } = useFormContext();
    const [loading, setLoading] = useState(false);
    const employeeId = getValues("employeeID");


    const onChange = async (e: any, value: boolean) => {
        if (!value) {
            const fileName = getValues("taxCoordFile");
            if (fileName) {
                setLoading(true);
                await remove(fileName, employeeId);
                setValue("taxCoordFile", undefined);
                setLoading(false);
            }
        }
    }


    return (
        <>
            <Checkbox label={t("taxCoords.title")} name="isTaxCoordination" onChange={onChange} disabled={loading} />
            {parseBool(watch("isTaxCoordination")) &&
                <FileComponent title={t("taxCoords.reasons.3")}  filename={t("taxCoords.files.3")} name="taxCoordFile"
                setLoadingFile={setLoadingFile}   setLoading={setLoading} loadingData={loading} />
            }
            {parseBool(watch("isHasAdditionalRevenue")) && !parseBool(watch("isTaxCoordination")) &&
                <FormHelperText error={true} >{t("max_taxes")}</FormHelperText>
            }
        </>
    );
};


