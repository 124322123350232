import useFileService from '../../utils/file-storage';
import { useFormContext } from "react-hook-form";
import { useCallback, useEffect, useState } from "react";
import UploadButton from "./UploadButton";
import { Link, Grid, FormHelperText, FormControl, FormLabel, IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import Loading from '../Loading';
import { getExtension, resolve } from '../../utils/utils';
import config from '../../config';

export interface FileProps {
    onUpload?: (file: File) => void;
    onRemove?: () => void;
    filename: string;
    name: string;
    title?: string
    fullWidth?: boolean;
    required?: boolean;
    setLoading?: (loading: boolean) => void;
    loadingData?: boolean;
    label?: string;
    folderName?: string
    fileOnLoad?: string
    copyFinished?: boolean
    setLoadingFile?: (loading: boolean) => void;
}

export default (props: FileProps) => {
    const { filename, label, name, onUpload, onRemove, folderName, fileOnLoad, loadingData,setLoadingFile } = props;
    const [loading, setLoading] = useState(loadingData || false);
    const [fetched, setFetched] = useState(false);

    const { getClient, upload, remove } = useFileService();
    const { getValues, setValue, formState: { errors }, clearErrors, watch } = useFormContext()
    const shareName = config.shareFileName;
    const blobName = watch(name);
    const employeeId = getValues("employeeID");
    const directory = folderName ? folderName : `${config.tempDirectory}/${employeeId}`

    const getFolderClient=useCallback(async ()=>{
        const serviceClient = await getClient();
        if (serviceClient) {
            const shareClient = serviceClient.getShareClient(shareName)
            return shareClient.getDirectoryClient(directory)
        };
    },[directory,shareName,getClient])


    useEffect(() => {
        const load = async () => {
            setFetched(true);
            setLoading(true);
            setLoadingFile?.(true)
            const directoryClient = await getFolderClient();
            if (directoryClient) {
                let dirIter1 = directoryClient.listFilesAndDirectories();
                for await (const item of dirIter1) {
                    if (item.kind !== "directory") {
                        if (item.name.includes(fileOnLoad || filename)) {
                            setValue(name, item.name);
                        }

                    }
                }
         
                setLoading(false);
                       setLoadingFile?.(loading?true:false)
            }
        }
        if (!fetched && !loading)
            load();


    }, [fetched, filename, getValues, setValue, name, fileOnLoad, loading,blobName,getFolderClient,setLoadingFile]);



    const onFileSelected = async (file: any) => {
        if (file) {
            setLoading(true);
            props.setLoading?.(true);
            setLoadingFile?.(true)
            const employeeId = getValues("employeeID");
            const extension = getExtension(file);
            onUpload?.(file);
            const fileName = `${filename}.${extension}`
            let fileRes = await upload(file, fileName, employeeId);
            setValue(name, fileRes?.name);
            clearErrors(name);
            setLoadingFile?.(false)
            setLoading(false);
            props.setLoading?.(false);
        }
    };

    const removeFile = async () => {
        props.setLoading?.(true);
        onRemove?.();
        setLoadingFile?.(true)
        setLoading(true);
        await remove(blobName, employeeId);
        setLoading(false);
        setLoadingFile?.(false)
        setValue(name, null);
        props.setLoading?.(false);
    }


    const preview = async () => {
        const directoryClient = await getFolderClient();
        if (directoryClient) {
            const fileClient = directoryClient.getFileClient(blobName);
            if (fileClient) {
                await fileClient.setHttpHeaders({ fileContentDisposition: "inline" })
                const element = document.createElement('a');
                element.href = fileClient.url+"&date="+new Date().getTime()
                element.target = '_blank';
                element.click();
            }
        }
    }

    const download = async () => {

        const directoryClient = await getFolderClient();
        if (directoryClient) {
            const fileClient = directoryClient.getFileClient(blobName);
            setLoading(true)
                await fileClient.setHttpHeaders({ fileContentDisposition: "download" })
                const element = document.createElement('a');
                element.href = fileClient?.url+"&xyz="+new Date().getTime();
                const employeeId = getValues("employeeID");
                element.download = element.name.replace(`${employeeId}`, '');
                element.click();

                setLoading(false);
            }        

    }

    const error = resolve(name, errors);

    return (<FormControl fullWidth error={!!error}>
        <Grid container>
            <Grid item xs={12} >
                <FormLabel>
                    <UploadButton onFileSelected={onFileSelected} disabled={loading || !!blobName} {...props} />
                </FormLabel>
            </Grid>
            {loading && <Grid xs={12} item display="flex" alignItems="center"><Loading size={30} /></Grid>}

            {!loading && blobName && <Grid xs={12} item display="flex" alignItems="center">
                <IconButton onClick={download}><DownloadIcon /></IconButton>
                <Link component="span" style={{ cursor: "pointer" }} onClick={preview}>
                    {label || filename}
                </Link>
                <IconButton onClick={removeFile}><DeleteIcon /></IconButton>
            </Grid>}
            {!!error &&
                <Grid item>
                    <FormHelperText>{error?.message}</FormHelperText>
                </Grid>
            }
        </Grid>
    </FormControl>
    );
}
