import * as Yup from 'yup';


declare module "yup" {
	export interface StringSchema {
		/**
		 * Check for pisraeli id number validity.
		 *
		 * @param {String} [errorMessage=DEFAULT_MESSAGE] The error message to return if the validation fails.
		 */
		israeliIdentity(errorMessage?: string): StringSchema;
	}
}

Yup.addMethod(Yup.string, "israeliIdentity", function (message: string) {
	return this.test("israeliIdentity", '', function (value: any) {
		const { path, createError } = this;
		return isValidIsraeliID(value) || createError({ path, message });
	});
});

export function isValidIsraeliID(id: string) {
	id = id && id.trim();
	if (!id || id.length === 0) return true;
	
	const num = parseInt(id, 10)
	if (id.length > 9 || id.length < 5 || isNaN(num) || num === 0 )
		return false;

	// Pad string with zeros up to 9 digits
	id = id.length < 9 ? ("00000000" + id).slice(-9) : id;

	return Array
		.from(id, Number)
		.reduce((counter, digit, i) => {
			const step = digit * ((i % 2) + 1);
			return counter + (step > 9 ? step - 9 : step);
		}) % 10 === 0;
}