import { Grid } from "@mui/material";
import { useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import TextField from "./ui/TextField";

export default ({setChildrenAge}:any) => {
    const { t } = useTranslation();
    const { trigger} = useFormContext();

    const revalidate = useCallback(() => {
        setTimeout(() => {
        trigger("isToddlerChildren");
        }, 100);
    },[trigger])
     useEffect(()=>{
        setChildrenAge()
        revalidate()
      },[setChildrenAge,revalidate])

    return (<Grid container spacing={2}>
        <Grid item xs={12} md={6} >
            <TextField type="number" inputProps={{min:0}} label={t("children.1")} name="toddlerChildNumBorn" onChange={revalidate} />
        </Grid>
        <Grid item xs={12} md={6}>
            <TextField type="number" inputProps={{min:0}} label={t("children.2")} name="toddlerChildNum1to5" onChange={revalidate} />
        </Grid>
        <Grid item xs={12} md={6}>
            <TextField type="number" inputProps={{min:0}} label={t("children.3")} name="toddlerChildNum6to12" onChange={revalidate} />
        </Grid>
    </Grid>);
}
