import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import TextField from "../ui/TextField";
import config from "../../config";
import AutoComplete, { AutocompleteOption } from "../ui/AutoComplete";
import { useFormContext } from "react-hook-form";
import RadioButton from "../ui/RadioButton";
import axios from "axios";

export interface Bank {
    id: string,
    name: string,
    nameEn: string,
    bankBranches: Branch[]
}
export interface Branch {
    id: string,
    bankId: string
    code: number
    name: string,
    nameEn: string
}

export default () => {
    const { setValue, getValues } = useFormContext();
    const { t } = useTranslation();
    const [banks, setBanks] = useState<Bank[]>([])
    const [selectedBank, setSelectedBank] = useState<AutocompleteOption | null>()

    const { watch, clearErrors } = useFormContext()


    const bankBranches = (banks?.find((b) => b.id == selectedBank?.id)?.bankBranches.length === 0 ?
        [{ id: "0", bankId: selectedBank?.id, code: 0, name: "bank without branch", nameEn: "bank without branch", branchName: "" } as Branch] : banks?.find((b) => b.id == selectedBank?.id)?.bankBranches.sort((a, b) => a.code - b.code) || [])
        .map((item: any) => ({ id: item.code, label: item.code + ' ' + item.nameEn }))

    const bankNames = [...new Map(banks.map((item: any) => [item.id, ({ id: item.id, label: item.id + ' - ' + item.nameEn })])).values()]

    const onChangeBankName = ((newValue: AutocompleteOption | null) => {
        const foundBank = banks?.find((b) => b.id == newValue?.id)
        setValue('bank', (foundBank?.name))
        setValue('bankNumber', (foundBank?.id))
        setValue('bankBranchEn', undefined)
        setValue('bankBranchNumber', undefined)
        setSelectedBank(newValue)
    })

    const onChangeBranchName = ((newValue: AutocompleteOption | null) => {
        const branch = banks.find(b => b.id === selectedBank?.id)?.bankBranches.find(br => br.code === parseInt(newValue?.id || ""))
        setValue('bankBranch', branch?.name)
        setValue('bankBranchNumber', branch?.code)
    })

    const onChangeBankNameEn = () => {
        setValue('bank', getValues('bankEn'))
    }


    const onChange = (value:string) => {
        setValue('bankEn', null)
        setValue('bankBranchEn', null)
        setValue('bankBranchNumber', null)
        setValue('bankNumber', null)
        setValue('bankAddress', null)
        setValue('beneficiaryName', null)
        setValue('beneficiaryAddress', null)
        setValue('routingNumber', null)
        setValue('swiftCode', null)
        setValue('bankAccount', '')
        setValue("isIsraelBank",value==="1"?true:false)
        clearErrors(["bankEn", 'bankBranchEn', 'bankAccount', 'bankAddress', 'beneficiaryName', 'beneficiaryAddress', 'routingNumber', 'swiftCode']);
    }

    useEffect(() => {
        ; (async () => {
            const result = await axios.get(`${config.apiBanksUrl}`);
            const resData: Bank[] = result.data
            setBanks(resData)
        })()
    }, [])

    return (
        <Grid item container spacing={2} >
            <Grid item xs={12} md={12} >
                <RadioButton required name="isIsraeliBank" title="isIsrealiBank" options={[{ value: 1, label: "Yes" }, { value: 2, label: "No" }]} isRow={true} onChange={onChange} />
            </Grid>
            {
                (watch("isIsraeliBank") == 1) &&
                <>
                    <Grid item md={6} xs={12}>
                        <AutoComplete
                            options={bankNames}
                            name='bankEn'
                            label={t("bank")}
                            onChange={onChangeBankName}
                            getValue={(o) => o?.label}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <AutoComplete
                            options={bankBranches}
                            name='bankBranchEn'
                            label={t("bankBranch")}
                            onChange={onChangeBranchName}
                            getValue={(o) => o?.label} />

                    </Grid>
                </>
            }
            {
                (watch("isIsraeliBank") == 2) &&
                <>
                    <Grid item md={6} xs={12}>
                        <TextField name="bankEn" label={t("bank")} required onChange={onChangeBankNameEn} />
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <TextField name="bankAddress" label={t("bankAddress")} required />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextField name="beneficiaryName" label={t("beneficiaryName")} required />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextField name="beneficiaryAddress" label={t("beneficiaryAddress")} required />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextField name="routingNumber" label={t("routingNumber")} required />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextField name="swiftCode" label={t("swiftCode")} required />
                    </Grid>
                </>
            }
            <Grid item md={12} xs={12}>
                <TextField name="bankAccount" label={t("bankAccount")} required />
            </Grid>
        </Grid>
    );
}