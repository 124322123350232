import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import { Backdrop, Button, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import employerData from "../initialValues";
import { validationSchema } from "../validations/scheme";
import MainContainer from "./MainContainer";
import PersonalDetails from "./EmployeeInfo";
import Section from "./Section";
import Signature from "./Signature";
import ChildrenInfo from "./ChildrenInfo";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import RevenueInfo from "./RevenueInfo";
import OtherRevenue from "./OtherRevenue";
import PartnerInfo from "./PartnerInfo";
import TaxCoordination from "./TaxCoordination";
import TaxCreditPoints from "./TaxCreditPoints";
import EmployerInfo from "./EmployerInfo";
import YearInput from "./YearInput";
import useNormalize from "../utils/useNormalize";
import { useCallback, useState } from "react";
import SubmitError from "./SubmitError";
import Loading from "./Loading";
import { he, enUS } from "date-fns/locale";
import { useHistory } from "react-router-dom";
import useFileService from "../utils/file-storage";
import { generatePdf, download } from "../utils/generatePdf";
import { isResident } from "../utils/utils";
import { isMarried } from "../validations/validators";
import config from "../config";
import axios from "axios";

interface FormProps {
    data: any
    submit: any
    mail: string | undefined
}
export default ({ data, submit, mail }: FormProps) => {

    const { t } = useTranslation();
    const { normalize } = useNormalize();
    const history = useHistory();
    const { upload } = useFileService();
    const [error, setError] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [loadingFile, setLoadingFile] = useState(false);
    const resolver = yupResolver(validationSchema);

    const methods = useForm({
        defaultValues: { ...data, ...employerData },
        mode: "onChange",
        resolver
    });
    const setValue = methods.setValue;
    const getValues = methods.getValues;
    const clearErrors = methods.clearErrors;
    const locale = t("locale") === "he" ? he : enUS;

    const saveSpecialFields = (data: any) => {
        if (!data.passportNo)
            data.passportState = ""

        if (!data.partnerPassport)
            data.partnerPassportState = ""

        if (!isResident({ 'identityNo': data.identityNo })) {
            for (let i in data.employeeChildrens) {
                let childData = data.employeeChildrens[i];
                for (let key in childData) {
                    if (key === "childIdentityNo")
                        data.employeeChildrens[i][key] = "";
                }
            }
        }
    }


    const getAge = (dateString: string) => {

        var year = Number(config.taxYear)
        var birthDate = new Date(dateString);
        return year - birthDate.getFullYear()

    }

    const setChildrenAge = useCallback(() => {

        ///7
        var isChildrenHold = getValues("isChildrenHold")
        ///8
        var isToddlerChildren = getValues("isToddlerChildren")
        var genderId = getValues("genderID")
        var isSingleParentHoldChildren = getValues("isSingleParentHoldChildren")
        var maritalStatusID = getValues("maritalStatusID")
        var toddlerChildNumBorn = 0
        var toddlerChildNum1to5 = 0
        var toddlerChildNum6to12 = 0
        var childrenNumberBorn = 0
        var childrenNumber1to5 = 0
        var childrenNumber6to12 = 0
        var childrenNumber13to17 = 0
        var childrenNumber18 = 0

        var index = 0;
        var children = getValues("employeeChildrens")
        children.forEach((child: { childBirthDate: string, isHold: boolean, isBIL: boolean }) => {
            clearErrors(`employeeChildrens.${index}.isHold`)
            var errorChild = false;
            var is7 = false
            var is8 = false
            var age = getAge(child.childBirthDate)
            if (isChildrenHold) {
                if (genderId == 2 && isMarried(maritalStatusID))
                    is7 = true;
                else if (!isMarried(maritalStatusID) && child.isHold && child.isBIL)
                    is7 = true;
                else if (isSingleParentHoldChildren && child.isHold)
                    is7 = true;
            }
            if (isToddlerChildren) {
                if (genderId == 1 && isMarried(maritalStatusID))
                    is8 = true
                else if (!isMarried(maritalStatusID) && !isChildrenHold && children.every((c: { isHold: boolean; }) => c.isHold === false))
                    is8 = true
                else if (genderId == 2 && !isMarried(maritalStatusID) && children.some((c: any) => c.isHold))
                    is8 = true
                else if (isSingleParentHoldChildren && !child.isHold)
                    is8 = true;
            }

            switch (true) {

                case age == 0: is7 ? childrenNumberBorn += 1 : is8 ? toddlerChildNumBorn += 1 : errorChild = true
                    break;
                case age > 0 && age <= 5: is7 ? childrenNumber1to5 += 1 : is8 ? toddlerChildNum1to5 += 1 : errorChild = true
                    break;
                case age > 5 && age <= 12: is7 ? childrenNumber6to12 += 1 : is8 ? toddlerChildNum6to12 += 1 : errorChild = true
                    break;
                case age > 12 && age <= 17: is7 ? childrenNumber13to17 += 1 : errorChild = true
                    break;
                case age == 18: is7 ? childrenNumber18 += 1 : errorChild = true
                    break;
            }
            if (errorChild)
                console.log("errorChild")
            //     if(errorChild)
            //  setErrorForm(`employeeChildrens.${index}.isHold`, { type: 'custom', message: 'הילד לא מחושב' })
            index += 1
        });
        if (getValues("isChildrenHold") == true) {
            setValue("childrenNumberBorn", childrenNumberBorn)
            setValue("childrenNumber1to5", childrenNumber1to5)
            setValue("childrenNumber6to12", childrenNumber6to12)
            setValue("childrenNumber13to17", childrenNumber13to17)
            setValue("childrenNumber18", childrenNumber18)
        }

        if (getValues("isToddlerChildren") == true) {
            setValue("toddlerChildNumBorn", toddlerChildNumBorn)
            setValue("toddlerChildNum1to5", toddlerChildNum1to5)
            setValue("toddlerChildNum6to12", toddlerChildNum6to12)
        }
    }, [getValues, setValue, clearErrors])

    const onError = (error: any) => {
        setError(true);
    }

    const onSuccess = async (data: any) => {
        const bytes = await generatePdf(data);
        if (bytes) {
            download(bytes, "Form101.pdf");
            const currentYear = config.taxYear;
            const path = `${data.employeeID}/${currentYear}`
       await  upload(new Blob([bytes]), `101.pdf`, data.employeeID, path)
        }
        history.push(data.isNew ? `/onboarding/${data.employeeID}` : `/submitted/${true}`);
    }

    const normalizeAndSubmit = async (values: any) => {
        saveSpecialFields(values)
        setIsSubmitting(true);
        normalize(values, methods.formState.dirtyFields);
        await submit(values, onSuccess, onError);
        setIsSubmitting(false);
        await axios.get(`Storage/CopyTempToEmployeeFolder?mail=${mail}`);
    }

    return (
        <MainContainer>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                <FormProvider {...methods} >
                    <form onSubmit={methods.handleSubmit(normalizeAndSubmit)} noValidate>
                        <Section title={t("taxYear")}>
                            <YearInput />
                        </Section>
                        <Section title={t("companyInfo")}>
                            <EmployerInfo />
                        </Section>
                        <Section title={t("personalInfo")}>
                            <PersonalDetails setChildrenAge={setChildrenAge}setLoadingFile={setLoadingFile} />
                        </Section>
                        <Section title={t("Children info")}>
                            <ChildrenInfo setChildrenAge={setChildrenAge} />
                        </Section>
                        <Section title={t("Revenue Info")}><RevenueInfo /></Section>
                        <Section   isMessage={true} title={t("Other Incomes Info")}><OtherRevenue /></Section>
                        <Section  isMessage={true} title={t("Partner Info")}><PartnerInfo /></Section>
                        <Section title={t("Changes During the year")}><Typography variant="caption">{t("changes note")}</Typography></Section>
                        <Section isMessage={true} title={t("Tax Credit Request")}><TaxCreditPoints setChildrenAge={setChildrenAge}  setLoadingFile={setLoadingFile} /></Section>
                        <Section  isMessage={true} title={t("Tax Coordination")}><TaxCoordination  setLoadingFile={setLoadingFile}/></Section>
                        <Section title={t("signature")}>
                            <Signature />
                        </Section>
                        <Grid item mb={2}>
                            <Button color="primary" variant="contained" fullWidth type="submit" disabled={loadingFile}>
                                {t("submit")}
                            </Button>
                        </Grid>
                    </form>
                </FormProvider>
            </LocalizationProvider>
            <SubmitError open={error} setOpen={setError} />
            <Backdrop
                open={isSubmitting || methods.formState.isSubmitting}
            >
                <Loading color="white" message={t("processing")} />
            </Backdrop>
        </MainContainer>
    );
}