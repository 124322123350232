import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { FormHelperText } from '@mui/material';
import { resolve } from '../../utils/utils';

export interface RadioButtonProps {
  title?: string;
  defaultValue?: string;
  ariaLabel?: string;
  name: string;
  options: OptionProps[];
  required?: boolean;
  isRow?: boolean;
  onChange?: (value: string) => void;
}

export interface OptionProps {
  value: any;
  label?: string;
}


export default (props: RadioButtonProps) => {
  const { t } = useTranslation();
  const { title, ariaLabel, name, options, required, isRow } = props;
  const { formState: { errors }, watch, control } = useFormContext();

  if (!name)
    throw new Error("name propety is missing. name is required to indentity fields in the form")

  const error = resolve(name, errors);

  return (<FormControl error={!!error} >
    {title && <FormLabel component="legend">{t(title)}{required && ' *'}</FormLabel>}
    <Controller
      control={control}
      name={name}
      render={({ field: { ref, onChange } }) => (
        <RadioGroup
          row={isRow}
          aria-label={ariaLabel}
          value={watch(name)}
          name={name}
          onChange={(e) => {
            onChange(e);
            props.onChange?.(e.target.value);
          }
          }
        >
          {options.map((option) => (<FormControlLabel
            value={option.value}
            control={<Radio inputRef={ref} />}
            label={t(option.label || option.value)}
            key={option.value}
          />))}
        </RadioGroup>
      )}
    />
    {!!error && <FormHelperText>{error?.message}</FormHelperText>}
  </FormControl>
  );
}
