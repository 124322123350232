import * as yup from 'yup';
import "yup-phone-lite";
import i18n from '../i18n';

const regex = /^[a-zA-Z0-9!@#$&()-`.+," '%]*$/

export default {
    bankEn: yup
        .string().nullable().trim()
        .required(() => i18n.t('validations.required', { field: i18n.t('bank') }))
        .when(['isIsraeliBank'], {
            is: (isIsraeliBank: any) => isIsraeliBank == 2,
            then: yup.string().nullable().trim().matches(regex, () => i18n.t('validations.inEnglish', { field: i18n.t('bankBranch') })),
        }),
        bankBranchEn:yup.string().nullable().trim().when(['isIsraeliBank'],
        {
            is: (isIsraeliBank: any) => isIsraeliBank == 1,
            then: yup.string().nullable().trim().required(() => i18n.t('validations.required', { field: i18n.t('bankBranch') })),
        }),
        bankAddress:yup.string().nullable().trim().when(['isIsraeliBank'],
        {
            is: (isIsraeliBank: any) => isIsraeliBank == 2,
            then: yup.string().nullable().trim().required(() => i18n.t('validations.required', { field: i18n.t('bankAddress') })),
        }),

        beneficiaryName:yup.string().nullable().trim().when(['isIsraeliBank'],
        {
            is: (isIsraeliBank: any) => isIsraeliBank == 2,
            then: yup.string().nullable().trim().required(() => i18n.t('validations.required', { field: i18n.t('beneficiaryName') })),
        }),
        beneficiaryAddress:yup.string().nullable().trim().when(['isIsraeliBank'],
        {
            is: (isIsraeliBank: any) => isIsraeliBank == 2,
            then: yup.string().nullable().trim().required(() => i18n.t('validations.required', { field: i18n.t('beneficiaryAddress') })),
        }),
        routingNumber:yup.string().nullable().trim().when(['isIsraeliBank'],
        {
            is: (isIsraeliBank: any) => isIsraeliBank == 2,
            then: yup.string().nullable().trim().required(() => i18n.t('validations.required', { field: i18n.t('routingNumber') }))
            .matches(/^\d+$/, () => i18n.t('validations.onlyNumbers', { field: i18n.t('routingNumber') })),
        }),
        swiftCode:yup.string().nullable().trim().when(['isIsraeliBank'],
        {
            is: (isIsraeliBank: any) => isIsraeliBank == 2,
            then: yup.string().nullable().trim().required(() => i18n.t('validations.required', { field: i18n.t('swiftCode') })),
        }),
    bankAccount: yup
        .string().nullable().trim()
        .required(() => i18n.t('validations.required', { field: i18n.t('bankAccount') }))
        .matches(/^\d+$/, () => i18n.t('validations.onlyNumbers', { field: i18n.t('bankAccount') }))
        .when(['isIsraeliBank'], {
            is: (isIsraeliBank: any) => isIsraeliBank == 1,
            then: yup.string().nullable().trim().max(9, () => i18n.t('validations.maxNumbers', { field: i18n.t('bankAccount') })),
        }),
    isIsraeliBank: yup
        .string().nullable().trim()
        .required(() => i18n.t('validations.requiredField'))
};