import { Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useFileService from "../utils/file-storage";
import { parseBool } from "../utils/utils";
import Checkbox from "./ui/Checkbox";
import File from "./ui/File";
import InfoLabel from "./ui/InfoLabel";

export interface Props {
    name: string;
    index: number;
    children?: any
    disabled?: boolean;
    dependsOn?: string | string[];
    setLoadingFile?:(loading:boolean)=>void
}

const dependencies: {
    [key: string]: string[]
} = {
    "isDisabled": [],
    "isNewImmigrant": ["newImmigrantDateFrom", "newImmigrantNoRevenueEndDate"],
    "isQualifyingLocality": ["qualifyingLocalityDateFrom", "qualifyingLocality"],
    "isHasDisabledChild": ["disabledChildrenNumber"],
    "isChildrenNotHold": [],
    "isPayingAlimony": [],
    "isReleasedSoldier": ["militaryServiceEndDate", "militaryServiceStartDate"],
    "isHasDegree": [],
    "isToddlerChildren": ["toddlerChildNumBorn", "toddlerChildNum1to5","toddlerChildNum6to12"],
    "isChildrenHold": ["childrenNumberBorn", "childrenNumber1to5", "childrenNumber6to12","childrenNumber13to17", "childrenNumber18"]
};


export default ({ name, index, children, disabled, dependsOn ,setLoadingFile}: Props) => {
    const { t, i18n } = useTranslation();
    const { watch, setValue, clearErrors, getValues } = useFormContext();
    const { remove } = useFileService();
    const [loading, setLoading] = useState(false);
    const employeeId = getValues("employeeID");

    const fileLabel = t(`taxCreditPoints.${index}.file`);
    const fileName = i18n.exists(`taxCreditPoints.${index}.fileName`) ? t(`taxCreditPoints.${index}.fileName`) : fileLabel;
    const hasFile = i18n.exists(`taxCreditPoints.${index}.file`);
    const fileField = `${name}File`;

    const onChange = async (e: any, value: any) => {
        if (!value) {
            if (hasFile) {
                const blobName = getValues(fileField);
                if (blobName) {
                    setLoading(true);
                    await remove(blobName, employeeId);
                    setValue(fileField, null);
                    clearErrors(fileField);
                    setLoading(false);
                }
            }

            const dependentFields = dependencies[name];
            dependentFields?.forEach((d: any) => {
                setValue(d, null);
                clearErrors(d);
            });
        }
    }

    return (
        <li>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Checkbox name={name} disabled={disabled! || loading} dependsOn={dependsOn} onChange={onChange} label={
                        <InfoLabel text={`taxCreditPoints.${index}.title`} tooltip={`taxCreditPoints.${index}.tooltip`} />}
                    />
                </Grid>
                {
                    parseBool(watch(name)) && <>
                        {children}
                        {
                            i18n.exists(`taxCreditPoints.${index}.note`) &&
                            <Grid item xs={12}>
                                <Typography variant="caption">{t(`taxCreditPoints.${index}.note`)}</Typography>
                            </Grid>
                        }
                        {
                            hasFile &&
                            <Grid item xs={12} md={6}>
                                <File title={`${t(`attachFile`)} (${fileLabel})`} required filename={fileName} name={fileField} setLoading={setLoading} loadingData={loading} setLoadingFile={setLoadingFile}  />
                            </Grid>
                        }
                    </>
                }
            </Grid>
        </li >

    );
}