import React from 'react'
import {Button, CircularProgress} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';
import * as Auth from '../../context/Auth'
import MicrosoftIcon from '../../icons/MicrosoftIcon'
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  submit: {
    padding: theme.spacing(1, 4),
    marginTop: theme.spacing(3)
  },
  whiteColor: {
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white
  }
}))

interface LoginButtonProps {
  color?: 'white'
}

export default function LoginButton({color}: LoginButtonProps) {
  const classes = useStyles()
  const {login, isLoggingIn} = Auth.useAuth()
  const { t } = useTranslation();


  return (
    <Button
      type="submit"
      variant="outlined"
      onClick={() => login()}
      startIcon={<MicrosoftIcon />}
      endIcon={
        isLoggingIn && (
          <CircularProgress
            size={24}
            className={color ? classes.whiteColor : ''}
          />
        )
      }
    >
      {t("Continue with Microsoft")}
    </Button>
  )
}
