
import * as Yup from 'yup';
import i18n from '../i18n';


declare module "yup" {
    export interface DateSchema {
        /**
         * throw error is the date is future date
         *
         * @param {String} [fieldName] field name for error message.
         */
        notFutureDate(fieldName: string): DateSchema;
    }
}


Yup.addMethod(Yup.date, "notFutureDate", function (field: string) {
    return this.max(new Date(), i18n.t('validations.maxDate', { field }));
});


