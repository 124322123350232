import { useEffect, useState } from "react";
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import { Backdrop, Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import useNormalize from "../../utils/useNormalize";
import Loading from "../Loading";
import MainContainer from "../MainContainer";
import Section from "../Section";
import i18n from "../../i18n";
import SubmitError from "../SubmitError";
import EmployeeDetails from "./EmployeeDetails";
import BankAccountDetails from "./BankAccountDetails";
import { validationSchema } from "../../validations/newEmployeeScheme";
import { useHistory } from "react-router-dom";

export default ({ data, submit }: any) => {

    const { t } = useTranslation();
    const { normalize } = useNormalize();
    const history = useHistory();
    const [error, setError] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const resolver = yupResolver(validationSchema);

    const methods = useForm({
        defaultValues: { ...data },
        mode: "onChange",
        resolver
    });

    const onError = (error: any) => {
        setError(true);
    }

    const onSuccess = async () => {
        history.push(`/submitted/${false}`);
    }

    const normalizeAndSubmit = async (values: any) => {
        setIsSubmitting(true);
        normalize(values, methods.formState.dirtyFields);
        await submit(values, onSuccess, onError);
        setIsSubmitting(false);
    }

    useEffect(() => {
        i18n.changeLanguage('en');
        document.body.dir = i18n.dir();
    }, []);

    return (
        <MainContainer>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={'enUS'} >
                <FormProvider {...methods} >
                    <form onSubmit={methods.handleSubmit(normalizeAndSubmit)} noValidate>
                        <Section title={t("Employee Details")}>
                            <EmployeeDetails />
                        </Section>
                        <Section title={t("Bank Account Info")}>
                            <BankAccountDetails />
                        </Section>

                        <Grid item mb={2}>
                            <Button color="primary" variant="contained" fullWidth type="submit">
                                {t("submit")}
                            </Button>
                        </Grid>
                    </form>
                </FormProvider>
            </LocalizationProvider>
            <SubmitError open={error} setOpen={setError} />
            <Backdrop
                open={isSubmitting || methods.formState.isSubmitting}
            >
                <Loading color="white" message={t("processing")} />
            </Backdrop>
        </MainContainer>
    );
}