import { Button, FormControl } from "@mui/material";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import makeStyles from "@mui/styles/makeStyles";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    cursor: 'pointer'
  },
  input: {
    opacity: 0,
  },
  label: {
    width: "100%",
    height: "56px"
  },
})
);

interface Props {
  onFileSelected?: (file: any) => void;
  filename: string;
  title?: string
  name: string;
  required?: boolean;
  disabled?: boolean;
}



export default ({ onFileSelected, title, name, required, disabled }: Props) => {

  const [guid, setGuid] = useState('');
  const classes = useStyles();
  const { register } = useFormContext();

  useEffect((): any => {
    setGuid(Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15));
  }, []);

  const onChange = (event: any) => {
    const file = event.target.files[0];

    if (file) {
      onFileSelected?.(file);
    }
    event.target.value = "";
  };

  return (
    <FormControl className={classes.root}>
      <input
        className={classes.input}
        id={guid}
        type="file"
        multiple={false}
        accept="application/pdf,image/*"
        disabled={disabled}
        onChange={onChange}
      />
      <label htmlFor={guid} className={classes.label}>
        <Button
          {...register(name)}
          disabled={disabled}
          variant="outlined"
          color="inherit"
          fullWidth
          component="span"
          className={classes.root}
        >
          <AttachFileIcon />
          {title}
          {required && ' *'}
        </Button>
      </label>
    </FormControl>
  );
}
