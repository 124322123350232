import { useTranslation } from "react-i18next";
import { parseBool } from "../utils/utils";
import PersonalInfo from "./PersonalInfo";
import Checkbox from "./ui/Checkbox";
import RadioButton from "./ui/RadioButton";
import { useFormContext } from "react-hook-form"
import { Typography } from "@mui/material";

export default () => {
    const { t } = useTranslation();
    const { watch, setValue } = useFormContext();
    const types = [
        {
            label: t("partnerDoesnotHave"),
            value: false,
        },
        {
            label: t("partnerHas"),
            value: true,
        }];

    const onChange = (value: any) => {
        if (!parseBool(value)) {
            setValue("isPartnerWorkRevenue", false)
            setValue("isPartnerOtherRevenue", false)
        }
    }



    return (
        parseInt(watch("maritalStatusID"), 10) === 2 ? // is Married
            <>
                <PersonalInfo prefix="partner" />
                <RadioButton name="isPartnerRevenue" options={types} onChange={onChange} />
                {parseBool(watch("isPartnerRevenue")) && <>
                    <Checkbox name="isPartnerWorkRevenue" label={t("work")} dependsOn="isPartnerRevenue" />
                    <Checkbox name="isPartnerOtherRevenue" label={t("otherRevenue")} dependsOn="isPartnerRevenue" />
                </>}
            </>
            : <Typography variant="caption" >{t("marriedOnly")}</Typography>
    );
}