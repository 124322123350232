import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox, { CheckboxProps as muiprops } from '@mui/material/Checkbox';
import { useFormContext, Controller } from 'react-hook-form';
import { FormControl, FormGroup, FormHelperText } from '@mui/material';
import { parseBool, resolve } from '../../utils/utils';


export interface CheckboxProps extends muiprops {
    name: string;
    label: React.ReactNode;
    dependsOn?: string | string[];
}


export default ({ name, label, dependsOn,  ...props }: CheckboxProps) => {

    const { formState: { errors }, watch, control, trigger } = useFormContext();

    if (!name)
        throw new Error('Checkbox name is required');
    
    const error = resolve(name, errors);

    return (
        <FormGroup>
            <FormControl error={!!error} >
                <FormControlLabel
                    style={{ pointerEvents: "none" }}
                    control={
                        <Controller
                            name={name}
                            control={control}
                            render={({field: {ref, onChange}}) => (<Checkbox
                                checked={parseBool(watch(name))}
                                {...props}
                                onChange={(e) => {
                                    onChange(e);
                                    props.onChange?.(e, e.target.checked);
                                    dependsOn && trigger(dependsOn);
                                }}
                                inputRef={ref}
                                style={{ pointerEvents: "auto" }} />
                            )}
                        />} label={label} />
                {!!error && <FormHelperText>{error?.message}</FormHelperText>}
            </FormControl>
        </FormGroup>
    );
};
