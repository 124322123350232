import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import DatePicker from "./ui/DatePicker";

export default () => {
    const { t } = useTranslation();
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <DatePicker label={t("army.startDate")} required name="militaryServiceStartDate" />
            </Grid>
            <Grid item xs={12} md={6}>
                <DatePicker label={t("army.endDate")} required name="militaryServiceEndDate" />
            </Grid>
        </Grid>
    );
};
