import * as yup from 'yup';
import config from '../config';
import i18n from '../i18n';
import { requiredDateValidator } from './validators';


const year = Number(config.taxYear);
const maxDate = new Date(year, 11, 31);
const minDate = new Date(year, 0, 1);


export default {
    "revenueTypeID": yup.number().nullable().required(i18n.t('validations.revenueType')),
    "startDateTaxYear": requiredDateValidator("start date")
        .min(minDate, i18n.t("validations.currentYear", { field: i18n.t("start date") }))
        .max(maxDate, i18n.t("validations.currentYear", { field: i18n.t("start date") })),
    "isHasAdditionalRevenue": yup.boolean().nullable().notRequired().test('isHasAdditionalRevenue', '', function (value: any) {
        const { path,
            createError,
            parent: { isAdditionalRevenueMonthly,
            isAdditionalRevenueExtra,
            isAdditionalRevenuePartial,
            isAdditionalRevenueDaily,
            isAdditionalRevenueAllowence,
            isAdditionalRevenueScholarship } } = this;
            
        if (isAdditionalRevenueMonthly || isAdditionalRevenueExtra || isAdditionalRevenuePartial || isAdditionalRevenueDaily || isAdditionalRevenueAllowence || isAdditionalRevenueScholarship) {
            return true;
        }
        return value ? createError({ path, message: i18n.t('validations.revenueType') }) : true;
    }),
    "isUseTaxCreditPoints": yup.boolean().nullable().notRequired().test("useTaxCreditPoints", '', function (value: any) {
        const { parent: { isHasAdditionalRevenue, isDontUseTaxCreditPoints }, path, createError } = this;
        if (!isHasAdditionalRevenue)
            return true;
        if (value && isDontUseTaxCreditPoints)
            return createError({ path: path, message: i18n.t('validations.creditPoint') });
        if (!value && !isDontUseTaxCreditPoints)
            return createError({ path: path, message: i18n.t('validations.useCreditPoint') });
        return true;
    })
};