import { useAuth } from "../../context/Auth"
import config from "../../config"
import { useLocation} from 'react-router-dom'
import AccessDenied from "./AccessDenied"
import Reports from "./Report"




const ReportNavigation = () => {
  const {roles} = useAuth()
  const location = useLocation()

  if (roles) {
    switch (location.pathname) {
      case '/reports': {
        if (roles?.some((r: any) => r === config.roles.admin)) return <Reports />
        break
      }
    }
  }
  return <AccessDenied></AccessDenied>
}

export default ReportNavigation
