import * as yup from 'yup';
import i18n from '../i18n';
import children from './children';
import partnerInfo from './partnerInfo';
import personalInfo from './personalInfo';
import revenue from './revenue';
import taxCreditPoints from './taxCreditPoints';
import { conditionalFileValidator } from './validators';

export const validationSchema = yup.object({
  ...personalInfo,
  ...taxCreditPoints,
  ...partnerInfo,
  ...children,
  ...revenue,
  "taxCoordFile": conditionalFileValidator('isTaxCoordination', 'taxCoords.files.3'),
  "signature": yup.string().nullable().required(i18n.t("validations.sign")),
});