import { parseBool } from "./utils";

export default () => {

    const normalizeBools = (data: any) => {
        for (const key in data) {
            if (key.startsWith("is")) {
                data[key] = parseBool(data[key]);
            }
        }
    };


    const normalize = (data: any, dirtyFields?: any) => {
        normalizeBools(data);
    };

    return { normalize };
}