import { Grid } from "@mui/material"
import { useTranslation } from "react-i18next";
import DatePicker from "./ui/DatePicker";
import RadioButton from "./ui/RadioButton";

export default () => {

    const { t } = useTranslation();

    let types = [
        { value: 1, label: t(`revenueTypes.1.label`), tooltip: t(`revenueTypes.1.tooltip`) },
        { value: 2, label: t(`revenueTypes.2.label`), tooltip: t(`revenueTypes.2.tooltip`) }
    ]

    return (
        <Grid container spacing={2} >
            <Grid item xs={12} md={6} >
                <RadioButton name="revenueTypeID" options={types} />
                <DatePicker  name="startDateTaxYear" label={t("start date")} />
            </Grid>
        </Grid>);
}