import * as yup from 'yup';
import {isValidIsraeliID} from "./israeli-id-validator"
import './future-date-validator';

import i18n from '../i18n';
import { nameValidator, requiredDateValidator } from './validators';
import { isResident } from '../utils/utils';
import config from '../config';


const year = config.taxYear;
const minDate = new Date(year - 19, 11, 31);

const childrenValidations = {
    "employeeChildrens": yup.array()
        .of(
            yup.object().shape({
                childBirthDate: requiredDateValidator('birthDate')
                    .notFutureDate(i18n.t('birthDate'))
                    .min(minDate, i18n.t('validations.childrenAge')),
                childIdentityNo: yup.string().nullable().trim()
                    .test('', '', function (value) {
                        const data = this.from[1].value;
                        const { createError, path } = this;
                        if (isResident(data)) {
                            if (!value)
                                return createError({ path, message: i18n.t('validations.required', { field: i18n.t('identity number') }) });
                            if (!isValidIsraeliID(value))
                                return createError({ path, message: i18n.t('validations.identityNo') });
                        }
                        return true;

                    }),
                childName: nameValidator('name')
            })
        )

        .nullable()
};

export default childrenValidations;