import { Snackbar, Alert } from "@mui/material";
import { useTranslation } from "react-i18next";

interface Props{
    open: boolean;
    setOpen: (open: boolean) => void;
}

export default ({open, setOpen}: Props) => {
    const { t } = useTranslation();
    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };

    return (
        <Snackbar open={open} onClose={handleClose}>
            <Alert variant="filled" onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                {t("error")}
            </Alert>
        </Snackbar>
    );
};
