import Checkbox from "./ui/Checkbox";
import InfoLabel from "./ui/InfoLabel";

interface Props {
    dependsOn?: string;
}

export default ({ dependsOn }: Props) => {

    // const { watch } = useFormContext();
    let types = [
        { value: 1, name: "isAdditionalRevenueMonthly" },
        { value: 3, name: "isAdditionalRevenueExtra" },
        { value: 2, name: "isAdditionalRevenuePartial" },
        { value: 4, name: "isAdditionalRevenueDaily" },
        { value: 5, name: "isAdditionalRevenueAllowence" },
        { value: 6, name: "isAdditionalRevenueScholarship" }
    ];

    return (<>
        {
            types.map(({ value, name }) => <Checkbox key={value} name={name} label={
                <InfoLabel text={`revenueTypes.${value}.label`} tooltip={`revenueTypes.${value}.tooltip`} />
            }
                dependsOn={dependsOn}
            />)}
    </>);
}