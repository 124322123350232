import Form from "./Form";
import Loading from "./Loading";
import Error from "./Error";
import { useData } from "../utils/useData";
import { useAuth } from "../context/Auth";
import { useLocation } from "react-router-dom";


export default () => {
    
    const { user } = useAuth();
    const location = useLocation();

    const loginAs = location ? new URLSearchParams(location.search).get("as") : null;
    const mail = loginAs || user?.email;
    const getUrl = `Form101/GetEmployee101FormData?mail=${mail}`
    const postUrl = `Form101/UpdateEmployee`

    const { data, loading, error, submit } = useData(getUrl, postUrl,true,mail);


    if (loading) {
        return <Loading />
    }

    if (error) {
        return <Error />
    }

    return <Form data={data} submit={submit}  mail={mail}/>

}