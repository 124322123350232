import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next";

export default ({ title, children, isMessage }: any) => {
    const { t } = useTranslation();

    return (
        <Accordion expanded >
            <AccordionSummary
            >
                <Stack direction={"column"}>
                    <Typography variant="subtitle2">{title}</Typography>
                    {isMessage && <Typography fontWeight={600} variant="subtitle1">{t("message about change in data")}</Typography>}
                    </Stack>
           

            </AccordionSummary>
            <AccordionDetails>
                {children}
            </AccordionDetails>
        </Accordion>
    );
}