export default {
  authConfig: {
    clientId: process.env.REACT_APP_AAD_CLIENT_ID || '',
    authority: process.env.REACT_APP_AAD_AUTHORITY || '',
    redirectUrl: process.env.REACT_APP_AAD_REDIRECT_URL || '',
    apiScope: process.env.REACT_APP_AAD_API_SCOPE || ''
  },
  isProduction: process.env.REACT_APP_PROD || false,
  apiUrl: process.env.REACT_APP_API_URL,
  generateSas: process.env.REACT_APP_GENERATE_SAS || '',
  shareFileName: process.env.REACT_APP_SHARE_FILE_NAME || '',
  tempDirectory: process.env.REACT_APP_TEMP_DIRECTORY || '',
  fileServiceUrl: process.env.REACT_APP_FILE_SERVICE_URL,
  supportEmail: process.env.REACT_APP_SUPPORT_EMAIL || '',
  apiCityUrl: process.env.REACT_APP_API_CITY_URL,
  apiCountryUrl: process.env.REACT_APP_API_COUNTRY_URL,
  apiBanksENUrl: process.env.REACT_APP_API_BANKSEN_URL,
  apiBanksUrl: process.env.REACT_APP_API_BANKS_URL,
  taxYear:process.env.REACT_APP_TAX_YEAR,

  roles: {
    admin: process.env.REACT_APP_ADMIN_ROLE || '101ReportAdmin',
   }

};