import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import TextField from "./ui/TextField";


export default () => {
    const { t } = useTranslation();

    return (
        <Grid item container spacing={2} >
            <Grid item md={6} xs={12}>
                <TextField name="employerName" label={t("name")} InputProps={{
                    readOnly: true,
                }} />
            </Grid>
            <Grid item md={6} xs={12}>
                <TextField name="employerAddress" label={t("address")} InputProps={{
                    readOnly: true,
                }} />
            </Grid>
            <Grid item md={6} xs={12}>
                <TextField name="employerPhone" label={t("phone")} InputProps={{
                    readOnly: true,
                }} />
            </Grid>
            <Grid item md={6} xs={12}>
                <TextField name="deductionsPortfolio" label={t("fileNumber")} InputProps={{
                    readOnly: true,
                }} />
            </Grid>
        </Grid>
    );
}