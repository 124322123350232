import { useState } from "react";
import { useTranslation } from "react-i18next";
import config from "../config";
import DatePicker from "./ui/DatePicker";

export default () => {

    const { t } = useTranslation();
    const [now] = useState(new Date(Number(config.taxYear), 1, 1));

    const month = now.getMonth();

    // const readonly = month < 11 && month > 0;

    let minYear = now.getFullYear();
    if (month === 0) {
        minYear = minYear - 1;
    }

    let maxYear = now.getFullYear();
    if (month === 11) {
        maxYear = maxYear + 1;
    }



    return (
        <DatePicker
            format="yyyy"
            name="taxYear"
            views={['year']}
            label={t('taxYear')}
            readOnly
            open={false}
            minDate={new Date(minYear, 0, 1)}
            maxDate={new Date(maxYear, 11, 31)}
        />
    );
}