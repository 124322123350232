import { Suspense } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { AuthContextProvider } from "./context/Auth";
import Loading from "./components/Loading";
import Logout from "./components/auth/Logout";
import Login from "./components/auth/Login";
import { ThemeProvider } from '@mui/material/styles';
import ProtectedRoute from "./components/auth/ProtectedRoute";
import { theme } from "./theme";
import DirectionWrapper from "./components/DirectionWrapper";
import Logo from "./components/Logo";
import FormWrapper from "./components/FormWrapper";
import ThankYou from "./components/ThankYou";
import { ConfirmDialogProvider } from 'react-mui-confirm';
import OnBoardingFormWrapper from "./components/onboarding/FormWrapper";
import ReportNavigation from "./components/reports/ReportNavigation";


export default () => {
  return (
    <DirectionWrapper>
      <ThemeProvider theme={theme}>
        <ConfirmDialogProvider>
          <Logo />
          <AuthContextProvider>
            <BrowserRouter>
              <Suspense fallback={<Loading />}>
                <Switch>
                  <ProtectedRoute exact path="/">
                    <FormWrapper />
                  </ProtectedRoute>
                  <ProtectedRoute exact path="/onboarding/:employeeId">
                    <OnBoardingFormWrapper />
                  </ProtectedRoute>
                  <ProtectedRoute exact path="/submitted/:isBack">
                    <ThankYou />
                  </ProtectedRoute>
                  <ProtectedRoute exact path="/reports">
                    <ReportNavigation />
                  </ProtectedRoute>
                  <Route path="/login">
                    <Login />
                  </Route>
                  <Route path="/logout">
                    <Logout />
                  </Route>
                </Switch>
              </Suspense>
            </BrowserRouter>
          </AuthContextProvider>
        </ConfirmDialogProvider>
      </ThemeProvider>
    </DirectionWrapper>
  );
};