import { Grid, Typography, Link } from "@mui/material"
import { useTranslation } from "react-i18next";
import config from "../config";

export default () => {
    const { t } = useTranslation();

    return (
        <Grid container spacing={2} justifyContent="center">
            <Grid item mt={4} textAlign="center">
                <Typography variant="h6">
                    {t("loadError.title")}
                </Typography>
                <Typography variant="subtitle1">
                    {t("loadError.message")}
                    <Link href={`mailto:${config.supportEmail}`}>{config.supportEmail}</Link>
                </Typography>
            </Grid>
        </Grid>);
}