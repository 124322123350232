import { useFormContext } from "react-hook-form";
import RadioButton from "./ui/RadioButton"

const dependencies = [
    'isPartnerRevenue',
    'isPartnerWorkRevenue',
    'isPartnerOtherRevenue',
    'partnerFirstName',
    'partnerLastName',
    'partnerBirthDate',
    'partnerIdentityNo',
    'partnerPassport',
    'partnerPassportState',
    'partnerImmigrationDate'
]

export default ({ setChildrenAge }: any) => {
    const { setValue, getValues, trigger, clearErrors } = useFormContext();
    let prevStatus = getValues('maritalStatusID');

    const onChange = (value: any) => {
        if (value != prevStatus && prevStatus == 2) {// was Married previously and now is not
            dependencies.forEach(name => {
                setValue(name, null);
                clearErrors(name);
            });
        }

        prevStatus = value;
        setChildrenAge()
        trigger('isChildrenHold');
        trigger('isToddlerChildren');
        trigger('isSingleParentHoldChildren');
        trigger('isSingleParent');

    }


    return (
        <RadioButton required name="maritalStatusID" title="maritalStatus"
            onChange={onChange}
            options={[
                { label: "single", value: 1 },
                { label: "married", value: 2 },
                { label: "divorcee", value: 3 },
                { label: "widower", value: 4 },
                { label: "separated", value: 5 }
            ]} />
    )
}