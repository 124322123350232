import axios from "axios";
import { useEffect, useState } from "react";
import { useAuth } from "../context/Auth";
import { dateTransformer } from "./dateTransformer";


export const useData = (getUrl: string, postUrl: string, isCopyData?: boolean, mail?: string | undefined) => {

    const [data, setData] = useState<any>();
    const [isCopyFinish, setIsCopyFinish] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    const { user } = useAuth();

    useEffect(() => {

        const load = async () => {
            if (user) {
                const result = await axios.get(getUrl);
                setData(result.data);
                if (!result.data) {
                    setError(true);
                    setLoading(false)
                }
            }
        }
        load()

    }, [user, getUrl])

    useEffect(() => {

        const copy = async () => {
            if (user) {
                const { data } = await axios.get(`Storage/CopyFiles?mail=${mail}`);
                if (data)
                    setIsCopyFinish(true)
                else {
                    setError(true);
                    setLoading(false)
                }
            }
        }
        isCopyData && copy();

    }, [user, isCopyData, mail])

    useEffect(() => {
        setLoading(!error&&!(data && (!isCopyData || isCopyFinish)))

    }, [data, isCopyFinish, isCopyData,error])

    const submit = async (values: any, onSuccess: (data: any) => void, onError: (error: any) => void) => {
        const instance = axios.create({
            transformRequest: [dateTransformer].concat(axios.defaults.transformRequest || [])
        })
        instance.interceptors.request = axios.interceptors.request
        await instance.post(postUrl, values,).then(() => onSuccess(values), onError);

    }

    return { data, loading, error, submit, isCopyFinish };

}