import { Grid, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddressInfo from './AddressInfo';
import RadioButton from './ui/RadioButton';
import TextField from './ui/TextField';
import PersonalInfo from './PersonalInfo';
import File from './ui/File';
import MaritalStatus from './MaritalStatus';
import Gender from './Gender';
import Hmo from './Hmo';
import { useFormContext } from 'react-hook-form';



export default ({setChildrenAge,setLoadingFile}:any) => {

  const { t } = useTranslation();
  const { trigger } = useFormContext();

  return (
    <Grid container spacing={2}>
      <PersonalInfo />
      <AddressInfo />
      <Grid item container spacing={2} >
        <Grid item md={6} xs={12}>
          <TextField
            name="phone"
            label={t("phone")}
            onChange={() => { trigger("cellPhone"); }}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            name="cellPhone"
            label={t("Mobile Phone Number")}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            type="email"
            name="email"
            label={t("email")}
          />
        </Grid>
      </Grid>

      <Grid item container spacing={2}>
        <Grid item xs={12}>
          <File
            required
            fullWidth
            name="identityCardFile"
            label={t("identityCardLabel")}
            filename={t("identityCard")}
            title={t("attachIdentityCard")}
            setLoadingFile={setLoadingFile}
          />
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <Typography variant='caption' gutterBottom>{t("identityCardNote")}</Typography>
          </Grid>
          <Grid item xs={12} mb={2}>
            <Typography variant='caption' gutterBottom>
              {t("identityCardNote2")} {" "}
              <Link href="https://jpg2pdf.com/" target="_blank" mr={1}>{t("merge")}</Link>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container spacing={2} >
        <Grid item xs={6} md={6} lg={2}>
          <Gender  setChildrenAge={setChildrenAge}/>
        </Grid>
        <Grid item xs={6} md={6} lg={2}>
          <MaritalStatus setChildrenAge={setChildrenAge}  />
        </Grid>
        <Grid item xs={6} md={6} lg={2}>
          <RadioButton required name="isIsraeliResident" title="isIsraeliResident" options={[{ value: true, label: "yes" }, { value: false, label: "no" }]} />
        </Grid>
        <Grid item xs={6} md={6} lg={2}>
          <RadioButton required name="isCooperativeMember" title="isCooperativeMember" options={[{ value: true, label: "yes" }, { value: false, label: "no" }]} />
        </Grid>
        <Hmo />
      </Grid>
    </Grid>
  );
};

