
import { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import capitalize from "../utils/capitalize";
import config from "../config";
import AutoComplete, { AutocompleteOption } from "./ui/AutoComplete";
import DatePicker from "./ui/DatePicker";
import TextField from "./ui/TextField";

// add prefix to the name, camelCase the name
const addPrefix = (name: string, prefix: string) => {
    name = prefix + capitalize(name);
    return name.charAt(0).toLowerCase() + name.slice(1);
}

export default ({ prefix = '' }: { prefix?: string }) => {
    const { trigger } = useFormContext();
    const { t } = useTranslation();
    const [options, setOptions] = useState<AutocompleteOption[]>([])
    const [loading, setLoading] = useState<boolean>(false)

    const revalidate = () => {
        trigger(addPrefix("identityNo", prefix));
        trigger(addPrefix("passportState", prefix));
    }

    const onChange = () => {
        trigger('firstName');
        trigger('lastName');
        trigger('partnerLastName');
        trigger('partnerFirstName');
        trigger('isIsraeliResident');
    }
    const initCountries = (() => {
        setLoading(true)
        fetch(`${config.apiCountryUrl}`)
            .then(response => response.json())
            .then(result => {
                const options = result.map((c: any) => ({
                    id: c.code,
                    label: c.name.trim(),
                }))
                setOptions(options)
                return options;
            }).then(() => {
                setLoading(false)
            })
    })

    useEffect(() => {
        initCountries();
    }, [])

    return (
        <Grid item container spacing={2} >
            <Grid item container md={6} xs={12}>
                <TextField
                    trim
                    name={addPrefix("identityNo", prefix)}
                    label={t("identityNo")}
                    onChange={onChange}
                />
            </Grid>
            <Grid item container md={6} xs={12} spacing={1}>
                <Grid item xs={6}>
                    <TextField
                        trim
                        onChange={revalidate}
                        name={prefix ? `${prefix}Passport` : "passportNo"}
                        label={t("passportNo")}
                    />
                </Grid>
                <Grid item xs={6}>
                    <AutoComplete
                        options={options}
                        name={addPrefix('passportState', prefix)}
                        label={t("passportState")}
                        getValue={(o) => o?.id}
                        onChange={revalidate}
                        loading={loading}
                    ></AutoComplete>
                </Grid>
            </Grid>
            <Grid item md={6} xs={12}>
                <TextField
                    required
                    name={addPrefix("firstName", prefix)}
                    label={t("firstName") + " - " + t("personalNote")}
                />
            </Grid>
            <Grid item md={6} xs={12}>
                <TextField
                    required
                    name={addPrefix("lastName", prefix)}
                    label={t("lastName") + " - " + t("personalNote")}
                />
            </Grid>
            <Grid item md={6} xs={12}>
                <DatePicker
                    required
                    name={addPrefix("birthDate", prefix)}
                    label={t("birthDate")}
                />
            </Grid>
            <Grid item md={6} xs={12}>
                <DatePicker
                    label={t("aliyaDate")}
                    name={addPrefix("immigrationDate", prefix)}
                />
            </Grid>
        </Grid >)
};