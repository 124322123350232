import { TextField, TextFieldProps } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { resolve } from "../../utils/utils";

interface TrimProps{
    trim?: boolean;
}

type Props = TextFieldProps & TrimProps;


export default (props: Props) => {

    const { control, formState: { errors } } = useFormContext(); // retrieve all hook methods

    if (!props.name)
        throw new Error("name propety is missing. name is required to indentity fields in the form")

    const error = resolve(props.name, errors);


    return (
        <Controller
            control={control}
            name={props.name}

            render={({ field: { ref, onChange, ...field } }) => {
                return(<TextField
                    fullWidth
                    inputRef={ref}
                    {...props}
                    {...field}
                    onChange={(e) => {
                        let value = e.target.value;
                        if(props.trim)
                            value = value.trim();
                        onChange(value);
                        props.onChange?.(e)
                    }}
                    placeholder={props.placeholder}
                    helperText={error?.message}
                    error={!!error}
                />
            )}
            }
        />);
}